import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import marketTopImage from "../assets/images/market_top.png";
import bitcoinImage from "../assets/images/BTCUSDT.svg";
import ethereumImage from "../assets/images/ETHUSDT.svg";
import bnbImage from "../assets/images/BNBUSDT.svg";
import solImage from "../assets/images/SOLUSDT.svg";
import xrpImage from "../assets/images/XRPUSDT.svg";
import dogeImage from "../assets/images/DOGEUSDT.svg";
import shibImage from "../assets/images/SHIBUSDT.svg";
import bchImage from "../assets/images/BCHUSDT.svg";
import axios from "axios";

interface CryptoData {
  id: string;
  name: string;
  symbol: string;
  current_price: number;
  market_cap: number;
  total_volume: number;
  price_change_percentage_24h: number;
}
const Markets = () => {
  const [cryptoDatas, setCryptoDatas] = useState<CryptoData[]>([]);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        const response = await axios.get<CryptoData[]>(
          "https://api.coingecko.com/api/v3/coins/markets",
          {
            params: {
              vs_currency: "usd",
              order: "market_cap_desc",
              per_page: 30,
              page: 1,
              sparkline: false,
            },
          }
        );
        setCryptoDatas(response.data);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    // Fetch data on component mount
    fetchCryptoData();

    // Set up interval to fetch data every 30 seconds
    const intervalId = setInterval(fetchCryptoData, 30000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <Desktop>
        <article className="w-full max-w-[1280px] h-auto mx-auto px-[80px]">
          <section className="relative my-[200px]">
            <div className="flex flex-col items-start justify-center">
              <div className="relative max-w-[540px]">
                <h1 className="font-extrabold text-[3.5rem] text-white">Markets</h1>
                <h2 className="mt-[-20px] font-extrabold text-[3.5rem] text-white">
                  Cryptocurrency
                </h2>
                <h2 className="mt-[-20px] font-extrabold text-[3.5rem] text-white">Prices</h2>
              </div>
            </div>
            <div className="absolute top-[-200px] right-[50px]">
              <img src={marketTopImage} alt="" className="w-[600px]" />
            </div>
          </section>
          <section className="mt-[300px]">
            <div className="flex flex-row items-center justify-start">
              <h1 className="font-extrabold text-[2.5rem] text-white">Markets Overview</h1>
            </div>
            <div className="pb-10 overflow-x-auto">
              <table className="min-w-full mt-10">
                <thead>
                  <tr className="text-[var(--t-color-desc)]">
                    <th className="px-4 py-2 text-left border-b-2 border-gray-300">Name</th>
                    <th className="px-4 py-2 text-center border-b-2 border-gray-300">Price</th>
                    <th className="px-4 py-2 text-center border-b-2 border-gray-300">24h Change</th>
                    <th className="px-4 py-2 text-center border-b-2 border-gray-300">24h Volume</th>
                    <th className="px-4 py-2 text-center border-b-2 border-gray-300">Market Cap</th>
                  </tr>
                </thead>
                <tbody>
                  {cryptoDatas.map((coin: any) => (
                    <tr key={coin.id} className="text-white">
                      <td className="flex items-center justify-start px-4 py-5 font-bold">
                        <img
                          src={coin.image}
                          alt={coin.name}
                          style={{ width: "30px", height: "30px" }}
                        />
                        <span className="ml-2">
                          {coin.name} ({coin.symbol.toUpperCase()})
                        </span>
                      </td>
                      <td className="px-4 py-5 font-bold text-center">
                        ${coin.current_price.toFixed(2)}
                      </td>
                      <td
                        className={`px-4 py-5 font-bold text-center ${
                          coin.price_change_percentage_24h > 0
                            ? "text-[var(--t-color-green)]"
                            : "text-[var(--t-color-red)]"
                        }`}
                      >
                        {coin.price_change_percentage_24h.toFixed(2)}%
                      </td>
                      <td className="px-4 py-5 font-bold text-center">
                        ${coin.total_volume.toLocaleString()}
                      </td>
                      <td className="px-4 py-5 font-bold text-center">
                        ${coin.market_cap.toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </article>
      </Desktop>
      <Mobile>
        <article className="w-full h-auto px-5 mx-auto">
          <section className="relative mt-[80px]">
            <div className="flex flex-col items-start justify-center">
              <div className="relative">
                <h1 className="font-extrabold text-[2.5rem] text-white leading-[50px]">Markets</h1>
                <h2 className="font-extrabold text-[2.5rem] text-white leading-[50px]">
                  Cryptocurrency
                </h2>
                <h2 className="font-extrabold text-[2.5rem] text-white leading-[50px]">Prices</h2>
              </div>
            </div>
          </section>
          <div className="pb-10 overflow-x-auto">
            <table className="min-w-full mt-10">
              <thead>
                <tr className="text-[var(--t-color-desc)]">
                  <th className="px-4 py-2 text-left border-b-2 border-gray-300">Name</th>
                  <th className="px-4 py-2 text-center border-b-2 border-gray-300">Price</th>
                  <th className="px-4 py-2 text-center border-b-2 border-gray-300">24h Change</th>
                  <th className="px-4 py-2 text-center border-b-2 border-gray-300">24h Volume</th>
                  <th className="px-4 py-2 text-center border-b-2 border-gray-300">Market Cap</th>
                </tr>
              </thead>
              <tbody>
                {cryptoDatas.map((coin: any) => (
                  <tr key={coin.id} className="text-white">
                    <td className="flex items-center justify-start px-4 py-5 font-bold">
                      <img
                        src={coin.image}
                        alt={coin.name}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <span className="ml-2">
                        {coin.name} ({coin.symbol.toUpperCase()})
                      </span>
                    </td>
                    <td className="px-4 py-5 font-bold text-center">
                      ${coin.current_price.toFixed(2)}
                    </td>
                    <td
                      className={`px-4 py-5 font-bold text-center ${
                        coin.price_change_percentage_24h > 0
                          ? "text-[var(--t-color-green)]"
                          : "text-[var(--t-color-red)]"
                      }`}
                    >
                      {coin.price_change_percentage_24h.toFixed(2)}%
                    </td>
                    <td className="px-4 py-5 font-bold text-center">
                      ${coin.total_volume.toLocaleString()}
                    </td>
                    <td className="px-4 py-5 font-bold text-center">
                      ${coin.market_cap.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </article>
      </Mobile>
    </div>
  );
};

export default Markets;
