export const isLogin = () => {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken ? true : false;
};

export function getSymbol(number: number): string {
  var symbol: string = "BTCUSDT";

  if (number == 1) {
    symbol = "BTCUSDT";
  }

  return symbol;
}

export function getStatementStatus(status: number): string {
  var statementStatus: string = "WAIT";

  if (status == 1) {
    statementStatus = "WAIT";
  } else if (status == 2) {
    statementStatus = "APPROVAL";
  } else if (status == 3) {
    statementStatus = "CANCEL";
  } else if (status == 4) {
    statementStatus = "REFUSE";
  }

  return statementStatus;
}

export function formatNumberWithCommas(number: number): string {
  // Ensure number is rounded to two decimal places
  const roundedNumber = Math.round(number * 100) / 100;

  // Convert number to string with fixed two decimal places
  let formatted = roundedNumber.toFixed(2);

  // Add commas for thousands separators
  formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formatted;
}

export function formatNumberWithNumber(number: number): string {
  // Ensure number is rounded to two decimal places
  const roundedNumber = Math.round(number * 100) / 100;

  // Convert number to string with fixed two decimal places
  let formatted = roundedNumber.toFixed(0);

  // Add commas for thousands separators
  formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formatted;
}

export const formatNumber = (num: string): string => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

export const formatDate = (localDateTime: number): string => {
  const date = new Date(localDateTime);

  const yy = String(date.getFullYear());
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  const dd = String(date.getDate()).padStart(2, "0");
  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");
  const ss = String(date.getSeconds()).padStart(2, "0");

  return `${yy}-${mm}-${dd} ${hh}:${min}:${ss}`;
};

export const getLevelName = (level: number): string => {
  var levelName: string = "회원";
  if (level === 1) {
    levelName = "회원";
  } else if (level === 2) {
    levelName = "인플루언서";
  } else if (level === 3) {
    levelName = "프리미엄인플루언서";
  } else if (level === 4) {
    levelName = "스타인플루언서";
  } else if (level === 5) {
    levelName = "메가인플루언서";
  } else if (level === 6) {
    levelName = "슈퍼인플루언서";
  } else if (level === 7) {
    levelName = "시크릿";
  }

  return levelName;
};
