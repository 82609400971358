import React, { useState } from "react";
import Header from "../components/ui/Header";
import { Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Footer from "../components/ui/Footer";

const Terms = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <div className="w-full h-full text-white">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full max-w-[1024px] mx-auto">
        <div className="relative content-top content-padding">
          <h1 className="font-bold text-[1.5rem] text-white text-center">Terms of Use</h1>
        </div>
        {/* Terms Section */}
        <section className="p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">Please read the Terms of Use carefully.</h2>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Introduction</h3>
            <p className="mb-4">
              Vibexglobal is a platform for users to conduct digital asset transactions and provide
              related services (hereinafter referred to as "the service" or "service"). The company
              operates the website{" "}
              <a
                  href="https://vibexglobal.com/"
                  className="text-[var(--t-color-point)]"
                >
                  https://vibexglobal.com
                </a>{" "}
              (hereinafter referred to as "this website") or "website".
            </p>
            <p>
              For the convenience of this Agreement, the Company and the Website collectively use
              "we" or other first-person names in this Agreement. As long as the natural person or
              other subject who visits the website is a user of this website, the convenience of
              this agreement is as follows, using "you" or other second person.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Important Reminder</h3>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                The digital assets themselves are not offered by any financial institution,
                corporation, or this Website.
              </li>
              <li>
                The digital asset market is new and unconfirmed, and will not necessarily expand.
              </li>
              <li>
                Digital assets are primarily used by speculators and are less commonly used on
                retail and commercial markets.
              </li>
              <li>
                The Company may suspend or terminate your account or use of the Service if it
                determines that you have violated this Agreement.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Digital Asset Trading Risk Disclaimer</h3>
            <p className="mb-4">
              Digital asset trading is highly risky and therefore not suitable for the vast majority
              of people. You acknowledge and understand that investment in digital assets may result
              in partial or total loss of your investment. You are advised to carefully consider and
              assess the risks before making any decisions.
            </p>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                You understand that this Website serves only as a venue for obtaining information,
                finding trading counterparties, and conducting transactions. This Website does not
                participate in your transactions.
              </li>
              <li>
                All opinions, information, and analyses on this Website are general market reviews
                and do not constitute investment advice.
              </li>
              <li>
                This Website may change its content at any time without notice and does not
                guarantee the accuracy of the information provided.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">General Provisions</h3>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                The User Agreement consists of the main body, Terms of Privacy, and other rules or
                policies that this Website may publish.
              </li>
              <li>
                By using the services offered by this Website, you agree to all terms and conditions
                of this Agreement.
              </li>
              <li>
                You confirm that all digital assets involved in transactions hereunder are legally
                acquired and owned by you.
              </li>
            </ol>
          </div>

          {/* Add other sections as needed */}
        </section>
        <Footer />
      </section>
    </div>
  );
};

export default Terms;
