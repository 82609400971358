// PercentageSlider.tsx
import React from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

interface PercentageSliderProps {
  value: number;
  onChange: (event: Event, newValue: number | number[]) => void;
}

const marks = [
  { value: 0, label: "0%" },
  { value: 20, label: "20%" },
  { value: 40, label: "40%" },
  { value: 60, label: "60%" },
  { value: 80, label: "80%" },
  { value: 100, label: "100%" },
];

const PercentageSlider: React.FC<PercentageSliderProps> = ({ value, onChange }) => {
  return (
    <div>
      <Slider
        className="mt-1"
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        aria-labelledby="percentage-slider"
        step={1}
        min={0}
        max={100}
        sx={{
          color: '#6666ff', // Change the slider bar color here
          '& .MuiSlider-rail': {
            backgroundColor: '#8888ff', // Change the rail (inactive part) color here
          },
        }}
      />
      <div className="flex justify-between mt-[-10px]">
        {marks.map((mark) => (
          <span className="text-white text-[0.8rem]" key={mark.value}>{mark.label}</span>
        ))}
      </div>
    </div>
  );
};

export default PercentageSlider;
