import React, { ChangeEvent } from 'react';
import '../../assets/css/CustomCheckbox.css';

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange, label }) => {
  return (
    <label className='relative flex flex-row justify-start items-center'>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkmark"></span>
      {label && <span className="label-text">{label}</span>}
    </label>
  );
};

export default CustomCheckbox;