import React, { createContext, useContext, useState, ReactNode } from "react";
import FeedbackPopup from "./ui/FeedbackPopup";

interface FeedbackContextProps {
  showFeedback: () => void;
  hideFeedback: () => void;
}

const FeedbackContext = createContext<FeedbackContextProps | undefined>(undefined);

export const FeedbackProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showFeedback = () => setIsVisible(true);
  const hideFeedback = () => setIsVisible(false);

  return (
    <FeedbackContext.Provider value={{ showFeedback, hideFeedback }}>
      {children}
      {isVisible && <FeedbackPopup onClose={hideFeedback} />}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = (): FeedbackContextProps => {
  const context = useContext(FeedbackContext);
  if (!context) {
    throw new Error("useFeedback must be used within a FeedbackProvider");
  }
  return context;
};
