import axios from "axios";
import React, { useEffect, useState } from "react";
import { Desktop, Mobile } from "../../hooks/mediaQuery";

const PriceInfo = (props: any) => {
  const [markPrice, setMarkPrice] = useState<number | null>(null);
  const [indexPrice, setIndexPrice] = useState<number | null>(null);
  const [fundingRate, setFundingRate] = useState<number | null>(null);
  const [fundingTime, setFundingTime] = useState<string | null>(null);
  const [highPrice, setHighPrice] = useState<number | null>(null);
  const [lowPrice, setLowPrice] = useState<number | null>(null);
  const [volume, setVolume] = useState<number | null>(null);
  const symbol = "BTCUSDT"; // Change to your desired trading pair

  useEffect(() => {
    // Fetch Mark Price and Index Price
    const fetchMarkIndexPrice = async () => {
      try {
        const response = await axios.get(
          `https://fapi.binance.com/fapi/v1/premiumIndex?symbol=${symbol}`
        );
        setMarkPrice(parseFloat(response.data.markPrice));
        setIndexPrice(parseFloat(response.data.indexPrice));
      } catch (error) {
        console.error("Error fetching mark and index price:", error);
      }
    };

    // Fetch Funding Rate and Countdown
    const fetchFundingRate = async () => {
      try {
        const response = await axios.get(
          `https://fapi.binance.com/fapi/v1/fundingRate?symbol=${symbol}&limit=1`
        );
        const fundingInfo = response.data[0];
        setFundingRate(parseFloat(fundingInfo.fundingRate) * 100); // Convert to percentage
        const fundingTime = new Date(fundingInfo.fundingTime);
        const now = new Date();
        const countdown = fundingTime.getTime() - now.getTime();
        setFundingTime(new Date(countdown).toISOString().substr(11, 8)); // Format as HH:mm:ss
      } catch (error) {
        console.error("Error fetching funding rate:", error);
      }
    };

    // Fetch 24h High, Low, and Volume
    const fetch24hStats = async () => {
      try {
        const response = await axios.get(
          `https://fapi.binance.com/fapi/v1/ticker/24hr?symbol=${symbol}`
        );
        setHighPrice(parseFloat(response.data.highPrice));
        setLowPrice(parseFloat(response.data.lowPrice));
        setVolume(parseFloat(response.data.volume));
      } catch (error) {
        console.error("Error fetching 24h stats:", error);
      }
    };
    fetchMarkIndexPrice();
    fetchFundingRate();
    fetch24hStats();

    // Set up an interval to refresh prices every 5 seconds
    const interval = setInterval(() => {
      fetchMarkIndexPrice();
      fetchFundingRate();
      fetch24hStats();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [symbol]);

  return (
    <section className="w-full h-full">
      <Desktop>
        <section>
          <div className="w-full h-1 bg-[var(--t-color-gray-800)]"></div>
          <div className="flex flex-row items-center justify-start w-full h-full px-5 py-2 bg-black">
            <span className="text-[1rem] font-extrabold text-white">{symbol}</span>
            <span className="ml-5 text-[1.2rem] font-extrabold text-red-500">
              {props.tickData == null ? "" : Number(props.tickData?.close).toFixed(2)}
            </span>
            <span
              className={`ml-1 px-2 py-1 text-[0.8rem] font-bold text-white rounded-lg ${
                Number(props.tickData?.percentChange) && Number(props.tickData?.percentChange) > 0
                  ? "bg-[var(--t-color-green)]"
                  : "bg-[var(--t-color-red)]"
              }`}
            >
               {props.tickData == null ? "" : Number(props.tickData?.percentChange).toFixed(2)}%
            </span>
            <div className="flex flex-col items-center justify-center ml-5">
              <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                Mark Price
              </span>
              <span className="text-[0.8rem] font-bold text-white">{markPrice?.toFixed(2)}</span>
            </div>
            <div className="flex flex-col items-center justify-center ml-5">
              <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                Index Price
              </span>
              <span className="text-[0.8rem] font-bold text-white">{indexPrice?.toFixed(2)}</span>
            </div>
            <div className="flex flex-col items-center justify-center ml-5">
              <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                Funding/Countdown
              </span>
              <span className="text-[0.8rem] font-bold text-white">
                {fundingRate?.toFixed(4)}% / {fundingTime}
              </span>
            </div>
            <div className="flex flex-col items-center justify-center ml-5">
              <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                24h High
              </span>
              <span className="text-[0.8rem] font-bold text-white">{highPrice?.toFixed(2)}</span>
            </div>
            <div className="flex flex-col items-center justify-center ml-5">
              <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                24h Low
              </span>
              <span className="text-[0.8rem] font-bold text-white">{lowPrice?.toFixed(2)}</span>
            </div>
            <div className="flex flex-col items-center justify-center ml-5">
              <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                24h Volume
              </span>
              <span className="text-[0.8rem] font-bold text-white">{volume?.toFixed(2)}</span>
            </div>
          </div>
          <div className="w-full h-1 bg-[var(--t-color-gray-800)]"></div>
        </section>
      </Desktop>
      <Mobile>
        <div>
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-col justify-center items-start px-2 py-4">
              <h1 className="text-[1rem] font-extrabold text-white">{symbol}</h1>
              <h2 className="mt-1 text-[1rem] font-extrabold text-red-500">
                {Number(props.tickData?.close).toFixed(2)}
              </h2>
              <span
                className={`px-2 py-1 text-[0.8rem] font-bold text-white rounded-lg ${
                  Number(props.tickData?.percentChange) && Number(props.tickData?.percentChange) > 0
                    ? "bg-[var(--t-color-green)]"
                    : "bg-[var(--t-color-red)]"
                }`}
              >
                {Number(props.tickData?.percentChange).toFixed(2)}%
              </span>
            </div>
            <div className="flex flex-col justify-start items-start w-[75%] px-2 py-4">
              <div className="flex flex-row items-between justify-between w-full">
                <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                  Mark Price
                </span>
                <span className="text-[0.8rem] font-bold text-white">{markPrice?.toFixed(2)}</span>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                  Index Price
                </span>
                <span className="text-[0.8rem] font-bold text-white">{indexPrice?.toFixed(2)}</span>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                  Funding<br></br>/Countdown
                </span>
                <span className="text-[0.8rem] font-bold text-white">
                  {fundingRate?.toFixed(4)}% / {fundingTime}
                </span>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center justify-center">
                <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                  24h High
                </span>
                <span className="ml-[40px] text-[0.8rem] font-bold text-white">
                  {highPrice?.toFixed(2)}
                </span>
              </div>
              <div className="flex flex-row items-center justify-center">
                <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                  24h Low
                </span>
                <span className="ml-5 text-[0.8rem] font-bold text-white">
                  {lowPrice?.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center justify-center">
                <span className="text-[0.8rem] font-bold text-[var(--t-color-gray-400)]">
                  24h Volume
                </span>
                <span className="ml-5 text-[0.8rem] font-bold text-white">
                  {volume?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </section>
  );
};

export default PriceInfo;
