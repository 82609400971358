import React, { useState } from "react";

const ToastPopUp = (props: any) => {
  var borderColor: String = "border-[#222222]";
  var bgColor: String = "bg-[#333333]";
  var desc: String = props.desc;
  var hidden: String = props.hidden;
  
  return (
    <div
      className={`absolute bottom-10 flex flex-row justify-start items-center w-[85%] h-auto mx-auto px-[21px] py-3 z-50 rounded-[50px] border ${borderColor} ${bgColor} ${hidden}`}
    >
      <span className="ml-2 text-sm text-white">{desc}</span>
    </div>
  );
};

export default ToastPopUp;
