import React, { useEffect, useState } from "react";
import closeImage from "../../assets/images/nav_btn_menu_close.svg";
import goImage from "../../assets/images/sys_icon_go.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

interface Menu {
  name: string;
  link: string;
  submenus?: { name: string; link: string }[]; // 서브메뉴는 이름과 링크를 가짐
}

const menus: Menu[] = [
  {
    name: "Markets",
    link: "/markets",
  },
  {
    name: "Trade",
    link: "/futures",
    submenus: [
      { name: "Futures", link: "/futures" },
      { name: "Option", link: "/options" },
    ],
  },
  {
    name: "Earn",
    link: "/earn",
  },
  {
    name: "Wallet",
    link: "/wallet",
  },
  {
    name: "Trade History",
    link: "/trade/history",
  },
  {
    name: "Support",
    link: "/faq",
  },
];
const Aside = (props: any) => {
  const navigate = useNavigate();

  const hostUrl = process.env.REACT_APP_HOST_URL;

  const isLogin = localStorage.getItem("accessToken");

  const [userInfo, setUserInfo] = useState<any>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const toggleSubMenu = (index: number, hasSubmenus?: boolean) => {
    if (!hasSubmenus) {
      navigate(menus[index].link); // 서브메뉴가 없으면 메인 메뉴의 링크로 이동
    } else if (openMenuIndex === index) {
      setOpenMenuIndex(null); // 동일한 메뉴 클릭 시 닫기
    } else {
      setOpenMenuIndex(index); // 다른 메뉴 클릭 시 열기
    }
  };

  useEffect(() => {
    requestUserInfo();
  }, []);

  function doLogout() {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  }

  /**
   * api
   */
  const requestUserInfo = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/info";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code == 0) {
        setUserInfo(response.data.data);
      }
    } catch (error) {}
  };

  return (
    <aside
      className={`top-0 left-0 z-50 w-full h-full bg-[var(--t-color-gray-800)] ${
        props.isMenuOpen ? "fixed" : "hidden"
      }`}
    >
      <div className="flex flex-row justify-end items-center px-5 w-full h-[48px] bg-[var(--t-color-gray-800)]">
        <button className="w-[30px] h-[30px]" onClick={props.closeMenu}>
          <img src={closeImage} alt="" className="w-full h-full" />
        </button>
      </div>
      {isLogin && (
        <Link to="/profile">
          <section className="relative flex flex-row justify-start items-center px-5 py-5">
            <div className="w-[50px] h-[50px] rounded-full bg-[var(--t-color-point)]"></div>
            <div className="flex flex-col ml-5">
              <span className="text-white text-[1rem] font-bold">{userInfo?.name}</span>
              <span className="text-white text-[0.8rem]">{userInfo?.email}</span>
            </div>
            <img
              src={goImage}
              alt=""
              className=" absolute right-5 top-1/2 transform -translate-y-1/2 w-[15px] h-[15px]"
            />
          </section>
        </Link>
      )}
      {!isLogin && (
        <section className="flex flex-row items-center w-full h-[48px]">
          <Link to="/register">
            <span className="ml-5 px-2 py-1 text-[0.9rem] border rounded-xl font-extrabold text-white cursor-pointer">
              Register
            </span>
          </Link>
          <Link to="/login">
            <span className="ml-3 px-2 py-1 text-[0.9rem] border rounded-xl font-extrabold text-white cursor-pointer">
              Login
            </span>
          </Link>
        </section>
      )}
      <div className="mx-5 mt-5">
        {menus.map((menu: any, index: number) => (
          <div key={index}>
            <div
              className="text-lg text-white cursor-pointer"
              onClick={() => toggleSubMenu(index, menu.submenus !== undefined)}
            >
              <div className="flex flex-row justify-between items-center w-full h-[60px]">
                {menu.name}
                <img src={goImage} alt="" className={`w-[15px] h-[15px] transition-transform duration-300 ${openMenuIndex === index ? "rotate-90" : ""}`} />
              </div>
            </div>
            {openMenuIndex === index && menu.submenus && (
              <div className="pl-4">
                {menu.submenus.map((submenu: any, subIndex: number) => (
                  <Link key={subIndex} to={submenu.link} className="py-3 text-base text-white block">
                    {submenu.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
        {/*
            {menus.map((menu, index) => (
          <Link key={index} className="text-lg text-white " to={links[index]}>
            <div className="flex flex-row justify-between items-center w-full h-[60px]">
              {menu}
              <img src={goImage} alt="" className="w-[15px] h-[15px]" />
            </div>
          </Link>
        ))}
          */}
      </div>
      {isLogin && (
        <div className="mx-5">
          <div
            className="flex flex-row justify-between items-center w-full h-[60px] text-lg text-red-500"
            onClick={doLogout}
          >
            Log out
          </div>
        </div>
      )}
    </aside>
  );
};

export default Aside;
