import { formatDate, formatNumberWithCommas } from "../common/Utils";

interface Props {
  transferHistories: any;
}

const TransferHistoryList: React.FC<Props> = ({ transferHistories }) => {
  const headers = ["Time", "Sell", "Amount", "Buy", "Amount", "Status"];

  return (
    <div className="w-full h-auto mt-5 px-2 pb-5">
      <div className="pb-3 overflow-x-auto">
        <table className="min-w-full overflow-hidden">
          <thead>
            <tr className="text-[0.8rem] leading-normal text-gray-400">
              {headers.map((header, index) => (
                <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light text-gray-600">
            {transferHistories.map((transferHistory: any, index: number) => (
              <tr key={index} className="text-[0.8rem] text-white">
                <td className="px-3 py-3 text-center">{formatDate(transferHistory.insertDate)}</td>
                <td className="px-3 py-3 text-center">{transferHistory.sell}</td>
                <td className="px-3 py-3 text-center">{formatNumberWithCommas(transferHistory.sellAmount)}</td>
                <td className="px-3 py-3 text-center">{transferHistory.buy}</td>
                <td className="px-3 py-3 text-center">{formatNumberWithCommas(transferHistory.buyAmount)}</td>
                <td className="px-3 py-3 text-center">SUCCESS</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransferHistoryList;
