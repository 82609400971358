import React, { useState } from "react";

interface Props {
  positions: any;
  openOrders: any;
}

const TradeAccount: React.FC<Props> = ({ positions, openOrders }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const positionHeaders = [
    "Time",
    "Futures",
    "OrderType",
    "Size",
    "Enty Price",
    "Mark Price",
    "Liq. Price",
    "Risk Rate",
    "Margin",
    "Realized PnL | %",
    "Close",
    "TP/SL",
  ];

  const openOrderHeaders = [
    "Time",
    "Futures",
    "OrderType",
    "Size",
    "Price",
    "Amount",
    "Reduce Only",
    "Post Only",
    "Trigger Condition",
    "TP/SL",
    "Cancel",
  ];

  return (
    <div className="w-full h-auto px-2">
      <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
        <div
          className={`flex flex-row items-center justify-center w-auto h-full ${
            selectedTabIndex == 0 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => setSelectedTabIndex(0)}
        >
          <span className="text-[0.9rem] font-normal text-white">Positions(0)</span>
        </div>
        <div
          className={`flex flex-row items-center justify-center w-auto h-full ml-3 ${
            selectedTabIndex == 1 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => setSelectedTabIndex(1)}
        >
          {" "}
          <span className="text-[0.9rem] font-normal text-white">Open Orders(0)</span>
        </div>
        <div
          className={`flex flex-row items-center justify-center w-auto h-full ml-3 ${
            selectedTabIndex == 2 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => setSelectedTabIndex(2)}
        >
          {" "}
          <span className="text-[0.9rem] font-normal text-white">Order History</span>
        </div>
      </div>
      {selectedTabIndex == 0 && (
        <div className="pb-3 overflow-x-auto">
          <table className="min-w-full overflow-hidden">
            <thead>
              <tr className="text-[0.8rem] leading-normal text-gray-400">
                {positionHeaders.map((header, index) => (
                  <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-sm font-light text-gray-600">
              {positions.map((position: any, index: number) => (
                <tr key={index} className="text-[0.8rem] text-white"></tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {selectedTabIndex == 1 && (
        <table className="min-w-full overflow-hidden">
          <thead>
            <tr className="text-[0.8rem] leading-normal text-gray-400">
              {openOrderHeaders.map((header, index) => (
                <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light text-gray-600">
            {openOrders.map((openOrder: any, index: number) => (
              <tr key={index} className="text-[0.8rem] text-white">
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TradeAccount;
