import React, { useState } from "react";
import "../../assets/css/Leverage.css";

interface Props {
  changeLeverage: (leverage: number) => void;
  onClose: () => void;
  leverage: number;
}

const Leverage: React.FC<Props> = ({ changeLeverage, onClose, leverage }) => {
  const [willLeverage, setWillLeverage] = useState<number>(leverage);

  const changeWillLeverage = (changedWillLeverage: number) => {
    setWillLeverage(changedWillLeverage);
  };

  const minusLeverage = () => {
    let minusWillLeverage = willLeverage - 1;
    if (minusWillLeverage <= 1) {
      minusWillLeverage = 1;
    }
    setWillLeverage(minusWillLeverage);
  };

  const plusLeverage = () => {
    let plusWillLeverage = willLeverage + 1;
    if (plusWillLeverage >= 100) {
      plusWillLeverage = 100;
    }
    setWillLeverage(plusWillLeverage);
  };

  return (
    <div className="modal-leverage show">
      <div className="inner">
        <a className="text-white" href="#" onClick={onClose}>
          ╳
        </a>
        <h3>Leverage</h3>
        <section className="adjust">
          <button onClick={minusLeverage}>━</button>
          <input type="text" id="adjLeverage" value={willLeverage + " x"} readOnly></input>
          <button onClick={plusLeverage}>╋</button>
        </section>
        <section className="btn">
          <button onClick={() => changeWillLeverage(1)}>1x</button>
          <button onClick={() => changeWillLeverage(25)}>25x</button>
          <button onClick={() => changeWillLeverage(50)}>50x</button>
          <button onClick={() => changeWillLeverage(75)}>75x</button>
          <button onClick={() => changeWillLeverage(100)}>100x</button>
        </section>
        <button className="mt-10 w-full h-[50px] py-2 bg-[var(--t-color-darkgray)] border border-gray-600 hover:border-[var(--t-color-point)] font-bold text-sm text-[var(--t-color-point)] rounded-xl" onClick={() => changeLeverage(willLeverage)}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default Leverage;
