import React, { useState } from "react";
import "../../App.css";
import "../../assets/css/Common.css";
import Select from "../component/Select";
import CustomCheckbox from "../component/CustomCheckbox";
import PercentageSlider from "./PercentageSlider";
import { formatNumber } from "../common/Utils";

interface Props {
  changeLeverage: () => void;
  changeOrderPrice: (orderPrice: number) => void;
  changeAmount: (amount: number) => void;
  doLong: () => void;
  doShort: () => void;
  tickData: any;
  asset: any;
  leverage: number;
  isLoggedIn: boolean;
}

const Trade: React.FC<Props> = ({
  changeLeverage,
  changeOrderPrice,
  changeAmount,
  doLong,
  doShort,
  tickData,
  asset,
  leverage,
  isLoggedIn,
}) => {
  const [isOpenSelectd, setMenuOpenSelected] = useState<boolean>(true);
  const [user, setUser] = useState<any>(null);
  const [selectedBuyType, setSelectedBuyType] = useState<number>(1);
  const [percent, setPercent] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const [isAutoPriceMarksChecked, setIsAutoPriceMarksChecked] = useState<boolean>(false);
  const [isTpSlChecked, setIsTpSlChecked] = useState<boolean>(false);
  const [percentSliderValue, setPercentSliderValue] = useState<number>(20);
  const [orderPrice, setOrderPrice] = useState<number>(0);
  const [orderPriceValue, setOrderPriceValue] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [amountValue, setAmountValue] = useState<string>("");

  const toggleOpenClose = (selectOpenClose: boolean) => {
    setMenuOpenSelected(selectOpenClose);
  };

  const changeBuyType = (buyType: number) => {
    setSelectedBuyType(buyType);
  };

  const changePercent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPercent(Number(event.target.value));
  };

  const changeButtonClick = (newValue: number) => {
    setSelectedBuyType(newValue);
  };

  const handleAutoPriceMarksCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutoPriceMarksChecked(event.target.checked);
  };

  const handleTpSlCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTpSlChecked(event.target.checked);
  };

  const handlePercentSliderChange = (event: Event, newValue: number | number[]) => {
    setPercentSliderValue(newValue as number);
  };

  const handleOrderPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, "");
    const regex = /^[0-9\b]+$/; // Only allows numbers and backspace
    if (regex.test(rawValue) || rawValue === "") {
      setOrderPriceValue(formatNumber(rawValue));
      setOrderPrice(parseInt(rawValue, 10));
    }
  };

  const handleOrderPriceBlur = () => {
    const numericValue = parseInt(orderPriceValue.replace(/,/g, ""), 10);
    if (numericValue < 0) {
      setOrderPriceValue("0");
      changeOrderPrice(0);
    } else if (isNaN(numericValue)) {
      setOrderPriceValue("");
      changeOrderPrice(0);
    } else {
      setOrderPriceValue(formatNumber(numericValue.toString()));
      changeOrderPrice(numericValue);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, "");
    const regex = /^[0-9\b]+$/; // Only allows numbers and backspace
    if (regex.test(rawValue) || rawValue === "") {
      setAmountValue(formatNumber(rawValue));
      setAmount(parseInt(rawValue, 10));
    }
  };

  const handleAmountBlur = () => {
    const numericValue = parseInt(amountValue.replace(/,/g, ""), 10);
    if (numericValue < 0) {
      setAmountValue("0");
      changeAmount(0);
    } else if (isNaN(numericValue)) {
      setAmountValue("");
      changeAmount(0);
    } else {
      setAmountValue(formatNumber(numericValue.toString()));
      changeAmount(numericValue);
    }
  };

  function goLogin() {
    window.location.href = "/login";
  }

  function getAmountWithType(percent = 0, type = 1) {
    const balance = asset?.balance;
    const ura = asset?.usingRequiredAmount;
    const lwra = asset?.longWaitingRequiredAmount;
    const swra = asset?.shortWaitingRequiredAmount;
  }

  function getCostWithType(type = 1) {}

  return (
    <div className="w-full h-auto pb-5">
      <div
        className="flex flex-row items-center justify-start w-full h-auto mt-2 cursor-pointer"
        onClick={changeLeverage}
      >
        <div>
          <span className="font-bold text-[0.9rem] text-white">Isolated</span>
        </div>
        <div className="ml-2">
          <span className="px-4 py-1 font-bold text-[0.9rem] bg-[var(--t-color-point)] text-white rounded-lg">
            {leverage}x
          </span>
        </div>
        {/*
         <div className="ml-2">
          <span className="font-bold text-[0.9rem] text-[var(--t-color-green)]">50x</span>
        </div>
           <div className="ml-2">
          <span className="font-bold text-[0.9rem] text-[var(--t-color-red)]">50x</span>
        </div>
        */}
      </div>
      <div className="w-full h-[30px] mt-3 bg-[var(--t-color-darkgray)] rounded-xl">
        <button
          className={`w-[50%] h-full text-sm text-white rounded-l-xl ${
            isOpenSelectd ? "bg-[var(--t-color-green)]" : ""
          }`}
          onClick={() => toggleOpenClose(true)}
        >
          Long
        </button>
        <button
          className={`w-[50%] h-full text-sm text-white rounded-r-xl ${
            !isOpenSelectd ? "bg-[var(--t-color-red)]" : ""
          }`}
          onClick={() => toggleOpenClose(false)}
        >
          Short
        </button>
      </div>
      <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
        <div
          className={`flex flex-row items-center justify-center w-auto h-full cursor-pointer ${
            selectedBuyType === 1 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => changeBuyType(1)}
        >
          <span
            className={`text-[0.9rem] font-normal ${
              selectedBuyType === 1 ? "text-white" : "text-[var(--t-color-unselected-color)]"
            }`}
          >
            Limit
          </span>
        </div>
        <div
          className={`flex flex-row items-center justify-center w-auto h-full ml-2 cursor-pointer ${
            selectedBuyType === 2 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => changeBuyType(2)}
        >
          <span
            className={`text-[0.9rem] font-normal ${
              selectedBuyType === 2 ? "text-white" : "text-[var(--t-color-unselected-color)]"
            }`}
          >
            Market
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-end mt-3">
        <CustomCheckbox
          checked={isAutoPriceMarksChecked}
          onChange={handleAutoPriceMarksCheckboxChange}
          label="Auto Price Marks"
        />
      </div>
      <div className="mt-3">
        <div className="mt-1">
          <h1 className="text-sm text-white">Price (USDT)</h1>
          <div className="flex items-center mt-1">
            {isAutoPriceMarksChecked && (
              <input
                type="text"
                className="w-full px-2 py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg"
                placeholder="Order price"
                value={formatNumber(tickData?.close)}
                onChange={handleOrderPriceChange}
                onBlur={handleOrderPriceBlur}
                readOnly
              />
            )}
            {!isAutoPriceMarksChecked && (
              <input
                type="text"
                className="w-full px-2 py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg"
                placeholder="Order price"
                value={orderPriceValue}
                onChange={handleOrderPriceChange}
                onBlur={handleOrderPriceBlur}
              />
            )}
          </div>
        </div>
        <div className="mt-2">
          <h1 className="text-sm text-white">Amount</h1>
          <div className="relative flex items-center w-full mt-1">
            <input
              type="text"
              className="w-full pl-2 pr-[100px] py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg"
              placeholder="Amount"
              value={amountValue}
              onChange={handleAmountChange}
              onBlur={handleAmountBlur}
            />
            <span className="absolute right-0 mr-3 text-sm text-white">BTC</span>
          </div>
        </div>
        <div className="relative flex flex-col items-center justify-center w-full bg-black">
          <div className="relative w-full">
            <PercentageSlider value={percentSliderValue} onChange={handlePercentSliderChange} />
          </div>
        </div>
        <div className="py-1 mt-3">
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Avail. USDT (BEP-20): <span className="text-white">0.00000000</span>
          </h3>
        </div>
        <div className="flex flex-row items-center justify-between py-1">
          <div className="flex flex-col items-start justify-center">
            <h3 className="text-[0.8rem] text-white">Max Long</h3>
            <h3 className="text-[0.8rem] text-[var(--t-color-green)]">0</h3>
          </div>
          <div className="flex flex-col items-start justify-center">
            <h3 className="text-[0.8rem] text-white">Max Short</h3>
            <h3 className="text-[0.8rem] text-[var(--t-color-red)]">0</h3>
          </div>
        </div>
        <div className="w-full mt-3 border border-gray-700"></div>
        <div className="mt-3">
          <CustomCheckbox
            checked={isTpSlChecked}
            onChange={handleTpSlCheckboxChange}
            label="TP/SL"
          />
        </div>
        {!isLoggedIn && (
          <div className="px-1 mt-3">
            <button
              className="w-full h-[40px] bg-[var(--t-color-point)] text-lg text-white rounded-3xl"
              onClick={goLogin}
            >
              Log In
            </button>
          </div>
        )}
        <div className="flex flex-row items-center justify-between mt-3">
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Cost: <span className="text-white">0</span>
          </h3>
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Cost: <span className="text-white">0</span>
          </h3>
        </div>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Max price: <span className="text-white">0</span>
          </h3>
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Min price: <span className="text-white">0</span>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Trade;
