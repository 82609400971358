import React from "react";
import { Desktop, Mobile } from "../../hooks/mediaQuery";
import logoImage from "../../assets/images/sys_icon_logo.svg";
import { Link } from "react-router-dom";
import { useFeedback } from "../FeedbackContext";

const Footer = () => {
  const { showFeedback } = useFeedback();
  return (
    <footer>
      <Desktop>
        <div className="w-full h-auto px-[10%] py-[50px] bg-[var(--t-color-footer)]">
          <div className="w-full px-[100px] mt-5">
            <section className="max-w-[1000px] mx-auto flex flex-row">
              <div>
                <h2 className="text-[0.9rem] text-white">ABOUT</h2>
                <div className="flex flex-col">
                  <Link
                    className="mt-3 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/"
                  >
                    About Vibex
                  </Link>
                  <Link
                    className="mt-1 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/privacy"
                  >
                    Privacy policy
                  </Link>
                  <Link
                    className="mt-1 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointere"
                    to="/terms"
                  >
                    Terms of use
                  </Link>
                  <Link
                    className="mt-1 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/legal"
                  >
                    Legal statement
                  </Link>
                </div>
              </div>
              <div className="ml-[70px]">
                <h2 className="text-[0.9rem] text-white">SUPORT</h2>
                <div className="flex flex-col">
                  <Link
                    className="mt-3 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/faq"
                  >
                    help&amp;faq
                  </Link>
                </div>
              </div>
              <div className="ml-[70px]">
                <h2 className="text-[0.9rem] text-white">SERVICE</h2>
                <div className="flex flex-col">
                  <Link
                    className="mt-3 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/fee"
                  >
                    fee
                  </Link>
                  <button
                    className="mt-1 text-left text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    onClick={showFeedback}
                  >
                    feedback
                  </button>
                </div>
              </div>
            </section>
            <div className="max-w-[1000px] mx-auto flex flex-row items-center mt-10">
              <img src={logoImage} alt="" className="w-[30px] h-[30px]" />
              <div className="flex flex-col justify-center ml-2">
                <span className="ml-1 font-bold text-[0.8rem] text-white cursor-pointer">
                  Vibex
                </span>
                <span className="ml-1 text-[0.8rem] text-[var(--t-color-gray-500)]">
                  Copyright © 2024 Vibex. All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="w-full h-auto px-[10%] py-[50px] bg-[var(--t-color-footer)]">
          <div className="w-full mt-5">
            <section className="relative flex flex-row flex-wrap">
              <div className="w-[48%]">
                <h2 className="text-[0.9rem] text-white">ABOUT</h2>
                <div className="flex flex-col">
                  <Link
                    className="mt-3 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="#"
                  >
                    About Vibex
                  </Link>
                  <Link
                    className="mt-1 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/privacy"
                  >
                    Privacy policy
                  </Link>
                  <Link
                    className="mt-1 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/terms"
                  >
                    Terms of use
                  </Link>
                  <Link
                    className="mt-1 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/legal"
                  >
                    Legal statement
                  </Link>
                </div>
              </div>
              <div className="w-[48%]">
                <h2 className="text-[0.9rem] text-white">SUPORT</h2>
                <div className="flex flex-col">
                  <Link
                    className="mt-3 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/faq"
                  >
                    help&amp;faq
                  </Link>
                </div>
              </div>
              <div className="w-[48%] mt-5">
                <h2 className="text-[0.9rem] text-white">SERVICE</h2>
                <div className="flex flex-col">
                  <Link
                    className="mt-3 text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    to="/fee"
                  >
                    fee
                  </Link>
                  <button
                    className="mt-1 text-left text-[0.8rem] text-[var(--t-color-gray-400)] uppercase cursor-pointer"
                    onClick={showFeedback}
                  >
                    feedback
                  </button>
                </div>
              </div>
            </section>
            <div className="flex flex-row items-center mt-10">
              <img src={logoImage} alt="" className="w-[30px] h-[30px]" />
              <div className="flex flex-col justify-center ml-2">
                <span className="ml-1 font-bold text-[0.8rem] text-white cursor-pointer">
                  Vibex
                </span>
                <span className="ml-1 text-[0.7rem] text-[var(--t-color-gray-500)]">
                  Copyright © 2024 Vibex. All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </footer>
  );
};

export default Footer;
