import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import "../assets/css/Common.css";
import DepositList from "../components/ui/DepositList";
import TradeFuturesList from "../components/ui/TradeFuturesList";
import TradeOptionsList from "../components/ui/TradeOptionList";
import axios from "axios";

const TradeHistory = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [tradeHistories, setTradeHistories] = useState([]);

  useEffect(() => {
    requesTradeFutureLookup();
  }, []);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function changeTab(index: number) {
    setSelectedTabIndex(index);
    if (index === 0) {
      requesTradeFutureLookup();
    } else {
      requesTradeOptionLookup();
    }
  }

  /**
   * api
   */
  const requesTradeFutureLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/order/lookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code === 0) {
        setTradeHistories(response.data.data.orders);
      }
    } catch (error) {}
  };  

  const requesTradeOptionLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/optionOrder/lookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code === 0) {
        setTradeHistories(response.data.data.list);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="content-top content-padding">
          <h1 className="font-bold text-xl text-white">Trade History</h1>
          {/*
            List
            */}
          <section className="mt-5 pb-5">
            <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
              <div
                className={`flex flex-row items-center justify-center w-auto h-full cursor-pointer ${
                  selectedTabIndex == 0 ? "border-b-2 border-white" : ""
                }`}
                onClick={() => changeTab(0)}
              >
                <span className="text-[0.9rem] font-normal text-white">Futures</span>
              </div>
              <div
                className={`flex flex-row items-center justify-center w-auto h-full ml-3 cursor-pointer ${
                  selectedTabIndex == 1 ? "border-b-2 border-white" : ""
                }`}
                onClick={() => changeTab(1)}
              >
                <span className="text-[0.9rem] font-normal text-white">Options</span>
              </div>
            </div>
            {selectedTabIndex === 0 && <TradeFuturesList tradeFutures={tradeHistories} />}
            {selectedTabIndex === 1 && <TradeOptionsList tradeOptions={tradeHistories} />}
          </section>
        </div>
      </section>
    </div>
  );
};

export default TradeHistory;
