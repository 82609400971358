import React, { useState, useCallback, useEffect } from "react";
import "../../assets/css/Manager.css";
import HeaderManager from "../../components/ui/HeaderManager";
import axios from "axios";
import { formatDate, formatNumberWithCommas } from "../../components/common/Utils";
import { Link, useNavigate } from "react-router-dom";
import ManagerPartnerList from "../../components/ui/ManagerPartnerList";
import ManagerPartnerInfo from "../../components/ui/ManagerPartnerInfo";

const Members = () => {
  const navigate = useNavigate();

  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [datas, setDatas] = useState<any>(null);

  useEffect(() => {
    const level = localStorage.getItem("level");
    let levelNumber: number = 1;
    if (level != null) {
      levelNumber = parseInt(level);
    }
    if (levelNumber < 2) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    requestManagerMemberLookup();
  }, []);

  /**
   * api
   */
  const requestManagerMemberLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/manager/members";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code === 0) {
        setDatas(response.data.data);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <section className="w-full h-full manager">
        <HeaderManager />
        <section className="main">
          <h1 data-lang="title_members">Members Managements</h1>
          <section className="search">
            <div className="form">
              <select id="search_text_type">
                <option value="name" data-lang="table_name">
                  Name
                </option>
                <option value="uid" data-lang="table_id">
                  ID
                </option>
                <option value="code" data-lang="tab_code">
                  Invitaion Code
                </option>
                <option value="email" data-lang="table_email">
                  Email
                </option>
              </select>
              <input type="text" id="search_text" placeholder="Input" />
              <button>Search</button>
            </div>
          </section>

          <section className="user">
            <table>
              <thead>
                <tr>
                  <th data-lang="table_no">No.</th>
                  <th data-lang="table_name">Name</th>
                  <th data-lang="table_code">Invitation Code</th>
                  <th data-lang="table_email">Email</th>
                  <th data-lang="table_amount">Amounts</th>
                  <th data-lang="table_join">Join Date</th>
                  <th data-lang="table_last_access">Last Accessed Time</th>
                </tr>
              </thead>
              <tbody>
                {datas?.map((data: any, index: number) => (
                  <tr key={index} className="text-[0.8rem] text-white">
                    <td>{data?.no}</td>
                    <td>{data?.name}</td>
                    <td>{data?.referralCode}</td>
                    <td>{data?.email}</td>
                    <td>
                      <select>
                        <option>Futures : {formatNumberWithCommas(data?.usdtm)}</option>
                        <option>BSC : {formatNumberWithCommas(data?.bsc)}</option>
                        <option>USDT : {formatNumberWithCommas(data?.usdt)}</option>
                      </select>
                    </td>
                    <td>{formatDate(data?.insertDate)}</td>
                    <td>{formatDate(data?.updateDate)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </section>
      </section>
    </div>
  );
};

export default Members;
