import React, { useState, useCallback } from "react";
import Header from "../components/ui/Header";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import "../assets/css/Login.css";
import axios from "axios";
import ToastPopUp from "../components/ui/ToastPopUp";
import AlertPopUp from "../components/ui/AlertPopUp";

const Login = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  // 아이디
  const [userid, setUserid] = useState("");
  // 패스워드
  const [password, setPassword] = useState("");
  const [isAutoLogin, setIsAutoLogin] = useState(false); // 로그인유지
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");
  // ToastPopUp
  const [toastPopUpHidden, setToastPopUpHidden] = useState<string>("hidden");
  const [toastPopUpDesc, setToastPopUpDesc] = useState<string>("");

  const useridChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Use a regular expression to allow only English letters
    const regex = /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]*$/;

    if (regex.test(inputValue)) {
      setUserid(inputValue);  // No error here
    }
  };

  const passwordChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPassword(event.target.value);
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      doLogin();
    }
  };

  function goRegister() {
    window.location.href = "/register";
  }

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  const showPopup = (popupDesc: string) => {
    setToastPopUpHidden("");
    setToastPopUpDesc(popupDesc);
    hidePopup();
  };

  const hidePopup = () => {
    setTimeout(function () {
      setToastPopUpHidden("hidden");
      setToastPopUpDesc("");
    }, 2000);
  };

  const doLogin = () => {
    if (userid.length === 0) {
      showAlertPopup("Please enter your email.");
      return;
    }
    /*
    let pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (!pattern.test(userid)) {
      showAlertPopup("Please Input your ID as an email.");
      return;
    }
    */
    if (password.length === 0) {
      showAlertPopup("Please enter your password.");
      return;
    }
    requestLogin();
  };

  const requestLogin = async (): Promise<void> => {
    try {
      const response = await axios.post(hostUrl + "/auth/login", {
        userid,
        password,
      });
      //console.log(response);
      if (response.data.errorMessage != null && response.data.errorMessage !== "") {
        showAlertPopup(response.data.errorMessage);
      } else {
        const level = response.data.level;
        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        localStorage.setItem("userid", userid);
        localStorage.setItem("level", level);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
       //router.push("/");
        window.location.replace("/");
      }
    } catch (error) {
      showAlertPopup("Please verify your username or password");
    }
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="flex flex-row justify-center w-full h-full bg-white">
        <div className="login">
          <h1>Login</h1>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Email (UserID)
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Email"
              value={userid}
              onChange={useridChange}
              required
            />
          </div>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Password
            </label>
            <input
              type="password"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Password"
              value={password}
              onChange={passwordChange}
              onKeyDown={handleEnterKeyPress}
              required
            />
          </div>
          <div className="px-10 mt-10">
            <button
              className="w-full h-[50px] bg-[var(--t-color-point)] text-lg text-white rounded-lg"
              onClick={doLogin}
            >
              Log In
            </button>
          </div>
          <h3 className="mt-5 text-sm text-center" onClick={goRegister}>
            Don't have an account?
            <span className="ml-1 font-bold text-[var(--t-color-point)] cursor-pointer">
              Sign up
            </span>
          </h3>
        </div>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
      <div className="flex flex-row justify-center w-full h-auto">
        <ToastPopUp hidden={toastPopUpHidden} desc={toastPopUpDesc} />
      </div>
    </div>
  );
};

export default Login;
