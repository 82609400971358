import React, { useState } from "react";
import { formatDate, formatNumberWithCommas } from "../common/Utils";

interface Props {
  tradeOptions: any;
}

const TradeOptionsList: React.FC<Props> = ({ tradeOptions }) => {
  const headers = [
    "Time",
    "Symbol",
    "Size",
    "Open Price",
    "Result Price",
    "Bet Type",
    "Result",
    "Expiration Time",
  ];


  function getResult(result: number): string {
    var resultString: string = "WAIT";
    if (result === 0) {
      resultString = "WAIT";
    } else if (result === 1) {
      resultString = "HIGHER";
    } else if (result === 2) {
      resultString = "LOWER";
    } else if (result === 3) {
      resultString = "EQ";
    }
    
    return resultString;
  }

  return (
    <div className="w-full h-auto mt-5 px-2 pb-5">
      <div className="pb-3 overflow-x-auto">
        <table className="min-w-full overflow-hidden">
          <thead>
            <tr className="text-[0.8rem] leading-normal text-gray-400">
              {headers.map((header, index) => (
                <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light text-gray-600">
            {tradeOptions.map((tradeOption: any, index: number) => (
              <tr key={index} className="text-[0.8rem] text-white">
                <td className="px-3 py-3 text-center">{formatDate(tradeOption.insertDate)}</td>
                <td className="px-3 py-3 text-center">BTCUSDT</td>
                <td className="px-3 py-3 text-center">{formatNumberWithCommas(tradeOption.volume)}</td>
                <td className="px-3 py-3 text-center">{formatNumberWithCommas(tradeOption.openPrice)}</td>
                <td className="px-3 py-3 text-center">{formatNumberWithCommas(tradeOption.resultPrice)}</td>
                <td className="px-3 py-3 text-center">{tradeOption.betType === 1 ? "HIGHER" : "LOWER"}</td>
                <td className="px-3 py-3 text-center">{getResult(tradeOption.betType)}</td>
                <td className="px-3 py-3 text-center">{formatDate(tradeOption.expireDate)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TradeOptionsList;
