import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import "../assets/css/Common.css";
import CustomSelect from "../components/ui/CustomSelect";
import DepositList from "../components/ui/DepositList";
import axios from "axios";
import AlertPopUp from "../components/ui/AlertPopUp";

const cryptos: any[] = [
  { value: "0", label: "Select crypto" },
  { value: "USDT", label: "USDT" },
];

const networks: any[] = [
  { value: "0", label: "Select network" },
  { value: "BEP-20", label: "BEP-20" },
];

const Deposit = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [selectedCrypto, setSelectedCrypto] = useState(cryptos[0].value);
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0].value);
  const [depositAddress, setDepositAddress] = useState<string>("");
  const [wallet, setWallet] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [bscAddress, setBscAddress] = useState<string>("");
  const [deposits, setDeposits] = useState([]);
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");

  useEffect(() => {
    requestUserInfo();
    requestDepositLookup();
  }, []);

  const handleCryptoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "") {
      setStepIndex(1);
    }
    setSelectedCrypto(e.target.value);
  };

  const handleNetworkChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "") {
      setStepIndex(2);
    }
    const selectedNetowork = selectedCrypto + " (" + e.target.value + ")";
    setSelectedNetwork(selectedNetowork);
  };

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function goDepositDetail() {
    window.location.href = "/deposit/history";
  }

  function copy() {
    if (!navigator.clipboard) {
      // Fallback for iOS
      const el = document.createElement("textarea");
      el.value = depositAddress;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      showAlertPopup("Copied to clipboard");
      return;
    }
    // For modern browsers supporting Clipboard API
    navigator.clipboard
      .writeText(depositAddress)
      .then(() => {
        showAlertPopup("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

   /**
   * api
   */
   const requestUserInfo = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/info";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.data.code == 0) {
        setUserInfo(response.data.data);
        if (response.data.data) {
          if (response.data.data.bscAddress) {
            setBscAddress(response.data.data.bscAddress);
            setDepositAddress(response.data.data.bscAddress);
          }
        }
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };

  const requestWallet = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/balance";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code == 0) {
      }
    } catch (error) {}
  };

  const requestDepositLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/deposit/lookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code == 0) {
        setDeposits(response.data.data.deposits);
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="content-top content-padding">
          <h1 className="font-bold text-xl text-white">Deposit</h1>
          <div className="flex flex-row justify-start items-start mt-5">
            <h1 className="w-[35px] h-[30px] bg-[var(--t-color-point)] font-bold text-sm text-white text-center leading-[30px] rounded-full">
              1
            </h1>
            <div className="flex flex-col justify-start items-start w-full h-auto ml-3 mt-1">
              <h3 className="font-bold text-white text-center">Select crypto</h3>
              <div className="control-width h-auto mt-3">
                <CustomSelect
                  options={cryptos}
                  value={selectedCrypto}
                  onChange={handleCryptoChange}
                  isDisable={stepIndex == 0 ? false : true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-start items-start mt-5">
            <h1
              className={`w-[35px] h-[30px] font-bold text-sm text-white text-center leading-[30px] rounded-full ${
                stepIndex == 1 ? "bg-[var(--t-color-7)]" : "bg-[var(--t-color-disable)]"
              }`}
            >
              2
            </h1>
            <div className="flex flex-col justify-start items-start w-full h-auto ml-3 mt-1">
              <h3
                className={`font-bold text-center ${
                  stepIndex == 1 ? "text-[var(--t-color-7)]" : "text-[var(--t-color-disable)]"
                }`}
              >
                Select Network
              </h3>
              <div className="control-width h-auto mt-3">
                <CustomSelect
                  options={networks}
                  value={selectedNetwork}
                  onChange={handleNetworkChange}
                  isDisable={stepIndex == 1 ? false : true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-start items-start mt-5">
            <h1
              className={`w-[35px] h-[30px] font-bold text-sm text-white text-center leading-[30px] rounded-full ${
                stepIndex == 1 ? "bg-[var(--t-color-7)]" : "bg-[var(--t-color-disable)]"
              }`}
            >
              3
            </h1>
            <div className="flex flex-col justify-start items-start w-full h-auto ml-3 mt-1">
              <h3
                className={`font-bold text-center ${
                  stepIndex == 2 ? "text-[var(--t-color-7)]" : "text-[var(--t-color-disable)]"
                }`}
              >
                Address Info
              </h3>
              <div className="control-width h-auto mt-3">
                {stepIndex == 2 && (
                  <div className="w-full h-auto p-5 border border-[var(--t-color-point)]">
                    <h1 className="font-bold text-lg text-white">{selectedNetwork} Address</h1>
                    <div className="w-full">
                      <p className="whitespace-normal break-words leading-normal text-base text-white">
                        {depositAddress}
                      </p>
                    </div>
                    <button
                      className="mt-2 px-5 py-3 text-white text-base border border-[var(--t-color-point)] hover:bg-[var(--t-color-point)] rounded-xl"
                      onClick={copy}
                    >
                      COPY
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/*
            List
            */}
          <section className="mt-10 pb-5">
            <h1 className="text-xl text-white">Recent Deposits</h1>
            <DepositList deposits={deposits} />
            <div className="flex flex-row justify-center mt-2">
              <button className="flex items-center text-base text-[var(--t-color-point)] hover:underline focus:outline-none" onClick={goDepositDetail}>
                <span>View more</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 ml-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </section>
        </div>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
    </div>
  );
};

export default Deposit;
