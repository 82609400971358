import React, { useState } from "react";
import Header from "../components/ui/Header";
import { Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Footer from "../components/ui/Footer";

const Privacy = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <div className="w-full h-full text-white">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full max-w-[1024px] mx-auto">
        <div className="relative content-top content-padding">
          <h1 className="font-bold text-[1.5rem] text-white text-center">Privacy</h1>
        </div>
        {/* Privacy Section */}
        <section className="shadow-lg rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Please read the Privacy Policy carefully.</h2>
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Parties</h3>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                vibexglobal (hereinafter referred to as 'the Company') is a company incorporated
                in HongKong under the laws of HongKong, and operates the Website{" "}
                <a
                  href="https://vibexglobal.com/"
                  className="text-[var(--t-color-point)]"
                >
                  https://vibexglobal.com
                </a>{" "}
                (hereinafter referred to as 'this Website' or 'the Website'), which is a platform
                dedicated to the transaction of digital assets and the provision of related services
                (hereinafter referred to as 'the Service'). For the convenience of wording in this
                Agreement, the Company and the Website are referred to collectively as 'we' or other
                applicable forms of first-person pronouns in this Agreement.
              </li>
              <li>
                All natural persons or other subjects who log onto this Website shall be users of
                this Website. For the convenience of wording in this Agreement, the users are
                referred to as 'you' or any other applicable forms of the second-person pronouns.
              </li>
              <li>
                For the convenience of wording in this Agreement, you and we are collectively
                referred to as “both parties”, and individually as “one party”.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Purposes of the Privacy Policy</h3>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                The Privacy Policy stipulates the types of information of yours that we may collect
                through your log into this Website, your registration with this Website, and/or use
                the services we offer, as well as how we shall use and protect the information so
                collected.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Your Consent</h3>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                To ensure that you have full confidence in our handling of personal data, you are
                advised to read and understand the terms of this Privacy Policy in detail. In
                particular, upon your log into our Website, regardless of whether you register with
                this Website, you shall be deemed to accept, agree, undertake and confirm that:
              </li>
              <li>
                You, on the basis of your own free will and your requisite consent, agree to
                disclose your personal information to us;
              </li>
              <li>You will comply with all the terms and limitations of this Privacy Policy;</li>
              <li>
                You agree that we may collect your information through your log into this Website,
                your registration with this Website, and/or your using the Services offered by us;
                you agree to any changes and modifications that we may make to our Privacy Policy in
                the future;
              </li>
              <li>
                You agree that our branches, affiliates, and employees may contact you in connection
                with the products and services that you may be interested in (unless you have
                indicated that you do not want to receive such information).
              </li>
            </ol>
          </div>

          {/* Add more sections as needed */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Cookies</h3>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                When you visit our Website, we use Google stats via cookies to record our
                performance and check the effect of online advertising.
              </li>
              <li>
                Cookies are usually used to record the habits and preferences of visitors in
                browsing the items on our Website. The information collected by cookies is
                non-registered and collective statistical data and does not involve personal data.
              </li>
              <li>
                Cookies enable the Website or service provider system to recognize your browser and
                capture and recall information.
              </li>
            </ol>
          </div>
        </section>
        <Footer />
      </section>
    </div>
  );
};

export default Privacy;
