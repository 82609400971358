import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatNumberWithCommas } from "../common/Utils";

interface PercentageSliderProps {
  todaySettlement: string;
  data: any;
}

const ManagerPartnerInfo: React.FC<PercentageSliderProps> = ({ todaySettlement, data }) => {
  return (
    <section>
      <div className="flex flex-row justify-between">
        <h2 className="font-bold text-[1.3rem]">
          {data?.level == 7 && "Secret Influencer"}
          {data?.level == 6 && "Super Influencer"}
          {data?.level == 5 && "Mega Influencer"}
          {data?.level == 4 && "Star Influencer"}
          {data?.level == 3 && "Premium Influencer"}
          {data?.level == 2 && "Influencer"}
        </h2>
        <span>{todaySettlement}</span>
      </div>
      <table>
        <thead>
          <tr>
            <th data-lang="table_influencer">Influencer(Rating)</th>
            <th data-lang="table_name">Name</th>
            <th data-lang="table_fee_rate">Fee Rates</th>
            <th data-lang="table_members">Members</th>
            <th data-lang="table_total_fee">Total Fee</th>
            <th data-lang="table_amount">Amount</th>
            <th data-lang="">Settlement</th>
            <th data-lang="table_phone">Phone</th>
            <th data-lang="">Day</th>
            <th data-lang="">Week</th>
            <th data-lang="">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {data?.level == 7 && "Secret"}
              {data?.level == 6 && "Super"}
              {data?.level == 5 && "Mega"}
              {data?.level == 4 && "Star"}
              {data?.level == 3 && "Premium"}
              {data?.level == 2 && "Influencer"}
            </td>
            <td>
              <Link to="">{data?.name}</Link>
            </td>
            <td>
              <input type="text" id="fee0" value={data.nowFee + "%"} readOnly />
            </td>
            <td>{data.memberCount}</td>
            <td></td>
            <td>
              <select>
                <option>Futures :</option>
                <option>BTC :</option>
                <option>USDT :</option>
              </select>
            </td>
            <td className="settlement">
              {" "}
              {data?.todaySettlement == null ? "0" : formatNumberWithCommas(data?.todaySettlement)}
            </td>
            <td>{data.phone}</td>
            <td className="settlement">
              {data?.todaySettlement == null ? "0" : formatNumberWithCommas(data?.todaySettlement)}
            </td>
            <td className="settlement">
              {data?.weekSettlement == null ? "0" : formatNumberWithCommas(data?.weekSettlement)}
            </td>
            <td className="settlement">
              {data?.totalSettlement == null ? "0" : formatNumberWithCommas(data?.totalSettlement)}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default ManagerPartnerInfo;
