import React, { useState } from "react";
import { formatDate, formatNumberWithCommas, getStatementStatus, getSymbol } from "../common/Utils";

interface Props {
  withdraws: any;
}

const WithdrawList: React.FC<Props> = ({ withdraws }) => {
  const headers = ["Time", "Symbol", "Amount", "Fee", "Address", "Status"];

  return (
    <div className="w-full h-auto mt-5 px-2 pb-5">
      <div className="pb-3 overflow-x-auto">
        <table className="min-w-full overflow-hidden">
          <thead>
            <tr className="text-[0.8rem] leading-normal text-gray-400">
              {headers.map((header, index) => (
                <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light text-gray-600">
            {withdraws.map((withdraw: any, index: number) => (
              <tr key={index} className="text-[0.8rem] text-white">
                <td className="px-3 py-3 text-center">{formatDate(withdraw.insertDate)}</td>
                <td className="px-3 py-3 text-center">USDT (BEP-20)</td>
                <td className="px-3 py-3 text-center">{formatNumberWithCommas(withdraw.amount)}</td>
                <td className="px-3 py-3 text-center">{formatNumberWithCommas(withdraw.fee)}</td>
                <td className="px-3 py-3 text-center">{withdraw.walletAddress}</td>
                <td className="px-3 py-3 text-center">{getStatementStatus(withdraw.status)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WithdrawList;
