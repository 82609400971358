import React, { useState } from "react";
import "../../assets/css/AlertPopUp.css";

const AlertPopUp = (props: any) => {
  return (
    <div className="popup-container">
      <div className="popup-alert">
        <div className={`popup-inner rounded-xl`}>
          <p>{props.message}</p>
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default AlertPopUp;
