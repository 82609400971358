import React, { useState } from "react";
import { formatDate, formatNumberWithCommas, getSymbol } from "../common/Utils";

interface Props {
  optionPositions: any;
  optionHistories: any;
}

const TradeOptionAccount: React.FC<Props> = ({ optionPositions, optionHistories }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const positionHeaders = [
    "Time",
    "Symbol",
    "Size",
    "Open Price",
    "Result Price",
    "Bet Type",
    "Result",
    "Estimated PnL",
    "Expiration Time",
  ];

  const orderHistoryHeaders = ["Expiration Time", "Result"];

  return (
    <div className="w-full h-auto px-2">
      <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
        <div
          className={`flex flex-row items-center justify-center w-auto h-full cursor-pointer ${
            selectedTabIndex == 0 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => setSelectedTabIndex(0)}
        >
          <span className="text-[0.9rem] font-normal text-white">Positions({optionPositions.length})</span>
        </div>
        <div
          className={`flex flex-row items-center justify-center w-auto h-full ml-3 cursor-pointer ${
            selectedTabIndex == 1 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => setSelectedTabIndex(1)}
        >
          <span className="text-[0.9rem] font-normal text-white">Order History</span>
        </div>
      </div>
      <div className="pb-3 overflow-x-auto">
        {selectedTabIndex == 0 && (
          <table className="min-w-full overflow-hidden">
            <thead>
              <tr className="text-[0.8rem] leading-normal text-gray-400">
                {positionHeaders.map((header, index) => (
                  <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-sm font-light text-gray-600">
              {optionPositions.map((optionPosition : any, index: number) => (
                <tr key={index} className="text-[0.8rem] text-white">
                  <td className="py-1 text-center">{formatDate(optionPosition.insertDate)}</td>
                  <td className="py-1 text-center">{getSymbol(optionPosition.symbol)}</td>
                  <td className="py-1 text-center">
                    {formatNumberWithCommas(optionPosition.volume)}
                  </td>
                  <td className="py-1 text-center">
                    {formatNumberWithCommas(optionPosition.openPrice)}
                  </td>
                  <td className="py-1 text-center">
                    {formatNumberWithCommas(optionPosition.resultPrice)}
                  </td>
                  <td className="py-1 text-center">
                    {optionPosition.betType == 1 ? "LONG" : "SHORT"}
                  </td>
                  <td className="py-1 text-center">
                    {optionPosition.result == 0 && "WAIT"}
                    {optionPosition.result == 1 && "LONG"}
                    {optionPosition.result == 2 && "SHORT"}
                    {optionPosition.result == 3 && "ERROR"}
                  </td>

                  <td className="py-1 text-center">{formatNumberWithCommas(optionPosition.settleFee)}</td>
                  <td className="py-1 text-center">{formatDate(optionPosition.expireDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
         {selectedTabIndex == 1 && (
          <table className="min-w-full overflow-hidden">
            <thead>
              <tr className="text-[0.8rem] leading-normal text-gray-400">
                {orderHistoryHeaders.map((header, index) => (
                  <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-sm font-light text-gray-600">
              {optionHistories.map((optionHistory: any, index: number) => (
                <tr key={index} className="text-[0.8rem] text-white">
                  <td className="py-1 text-center">{formatDate(optionHistory.expireDate)}</td>
                  <td className="py-1 text-center">
                    {formatNumberWithCommas(optionHistory.resultPrice)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TradeOptionAccount;
