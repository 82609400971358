import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import "../assets/css/Wallet.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { formatNumberWithCommas } from "../components/common/Utils";

const Wallet = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [userBalance, setUserBalance] = useState<any>(null);

  useEffect(() => {
    requestUserBalance();
  }, []);


  function goDeposit() {
    window.location.href = "/deposit";
  }
  
  function goWithdraw() {
    window.location.href = "/withdraw";
  }

  function goTransfer() {
    window.location.href = "/transfer";
  }

  function goTransferFutures() {
    window.location.href = "/transfer/futures";
  }

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  /**
   * api
   */
  const requestUserBalance = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/balance";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code == 0) {
        setUserBalance(response.data.data);
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };


  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="w-full h-auto px-5 content-top">
          <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
            <div
              className={`flex flex-row items-center justify-center w-auto h-full cursor-pointer ${
                selectedTabIndex == 0 ? "border-b-2 border-white" : ""
              }`}
              onClick={() => setSelectedTabIndex(0)}
            >
              <span className="text-[0.9rem] font-normal text-white">Overview</span>
            </div>
            <div
              className={`ml-3 flex flex-row items-center justify-center w-auto h-full cursor-pointer ${
                selectedTabIndex == 1 ? "border-b-2 border-white" : ""
              }`}
              onClick={() => setSelectedTabIndex(1)}
            >
              <span className="text-[0.9rem] font-normal text-white">Spot Wallet</span>
            </div>
            <div
              className={`ml-3 flex flex-row items-center justify-center w-auto h-full cursor-pointer ${
                selectedTabIndex == 2 ? "border-b-2 border-white" : ""
              }`}
              onClick={() => setSelectedTabIndex(2)}
            >
              <span className="text-[0.9rem] font-normal text-white">Futures Wallet</span>
            </div>
          </div>
          {selectedTabIndex == 0 && (
            <div>
              <div className="w-full h-auto mt-5 border border-[var(--t-color-light-point)] rounded-xl">
                <div className="p-5">
                  <h1 className="font-bold text-[0.9rem] text-white">Total Assets</h1>
                  <div className="flex flex-row justify-start items-center">
                    <div className="title-width">
                      <h1 className="mt-2 font-bold text-lg text-white">USDT (BEP-20)</h1>
                    </div>
                    <h1 className="mt-2 font-bold text-lg text-white">{userBalance == null ? "0" : formatNumberWithCommas(userBalance?.bsc)}</h1>
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    <div className="title-width">
                      <h1 className="mt-2 font-bold text-lg text-white">USDT Futures</h1>
                    </div>
                    <h1 className="mt-2 font-bold text-lg text-white">{userBalance == null ? "0" : formatNumberWithCommas(userBalance?.usdtm)}</h1>
                  </div>
                </div>
              </div>
              {
                /*
                  <div className="w-full h-auto mt-5 border border-[var(--t-color-light-point)] rounded-xl">
                <div className="p-5">
                  <h1 className="font-bold text-[0.9rem] text-white">Status</h1>
                  <div className="flex flex-row justify-start items-center">
                    <div className="title-width">
                      <h1 className="mt-2 font-bold text-lg text-white">BSC</h1>
                    </div>
                    <h1 className="mt-2 font-bold text-lg text-white">0.01</h1>
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    <div className="title-width">
                      <h1 className="mt-2 font-bold text-lg text-white">Unrealized Margin</h1>
                    </div>
                    <h1 className="mt-2 font-bold text-lg text-white">0.01</h1>
                  </div>
                </div>
              </div>
                */
              }
            
            </div>
          )}
          {selectedTabIndex == 1 && (
            <div>
              <div className="w-full h-auto mt-5 border border-[var(--t-color-light-point)] rounded-xl">
                <div className="p-5">
                  <h1 className="font-bold text-[0.9rem] text-white">Total Assets</h1>
                  <div className="flex flex-row justify-start items-center">
                    <div className="title-width">
                      <h1 className="mt-2 font-bold text-lg text-white">USDT (BEP-20)</h1>
                    </div>
                    <h1 className="mt-2 font-bold text-lg text-white">{userBalance == null ? "0" : formatNumberWithCommas(userBalance?.bsc)}</h1>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center mt-3 justify-start">
                <button className="px-3 py-2 text-white border border-[var(--t-color-point)] hover:bg-[var(--t-color-point)] rounded-xl" onClick={goDeposit}>Deposit</button>
                <button className="ml-3 px-3 py-2 text-white border border-[var(--t-color-point)] hover:bg-[var(--t-color-point)] rounded-xl" onClick={goWithdraw}>Withdraw</button>
                {
                  /*
                <button className="ml-3 px-3 py-2 text-white border border-[var(--t-color-point)] hover:bg-[var(--t-color-point)] rounded-xl" onClick={goTransfer}>Transfer</button>
                  */
                }
              </div>
            </div>
          )}
          {selectedTabIndex == 2 && (
            <div>
              <div className="w-full h-auto mt-5 border border-[var(--t-color-light-point)] rounded-xl">
                <div className="p-5">
                  <h1 className="font-bold text-[0.9rem] text-white">Total Assets</h1>
                  <div className="flex flex-row justify-start items-center">
                    <div className="title-width">
                      <h1 className="mt-2 font-bold text-lg text-white">USDT Futures</h1>
                    </div>
                    <h1 className="mt-2 font-bold text-lg text-white">{userBalance == null ? "0" : formatNumberWithCommas(userBalance?.usdtm)}</h1>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-start items-center mt-3">
                <button className="px-3 py-2 text-white border border-[var(--t-color-point)] hover:bg-[var(--t-color-point)] rounded-xl" onClick={goTransferFutures}>Transfer</button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Wallet;
