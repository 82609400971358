import React, { useState, useCallback, useEffect } from "react";
import "../../assets/css/Manager.css";
import HeaderManager from "../../components/ui/HeaderManager";
import axios from "axios";
import { formatNumberWithCommas } from "../../components/common/Utils";
import { Link, useNavigate } from "react-router-dom";
import ManagerPartnerList from "../../components/ui/ManagerPartnerList";
import ManagerPartnerInfo from "../../components/ui/ManagerPartnerInfo";
import { Console } from "console";
import AlertPopUp from "../../components/ui/AlertPopUp";

const Partner = () => {
  const navigate = useNavigate();

  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [data, setData] = useState<any>(null);
  const [superDatas, setSuperDatas] = useState<any>([]);
  const [megaDatas, setMegaDatas] = useState<any>([]);
  const [starDatas, setStarDatas] = useState<any>([]);
  const [premiumDatas, setPremiumDatas] = useState<any>([]);
  const [influencerDatas, setInfluencerDatas] = useState<any>([]);
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");

  useEffect(() => {
    const level = localStorage.getItem("level");
    let levelNumber: number = 1;
    if (level != null) {
      levelNumber = parseInt(level);
    }
    if (levelNumber < 2) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    requestManagerPartnerLookup();
  }, []);

  const handleFeeChange = (level: number, members: any[]) => {
    if (level == 6) {
      setSuperDatas(members);
    } else if (level == 5) {
      setMegaDatas(members);
    } else if (level == 4) {
      setStarDatas(members);
    } else if (level == 3) {
      setPremiumDatas(members);
    } else if (level == 2) {
      setInfluencerDatas(members);
    }
  };

  function doDistributePercent() {
    if (data?.level == 7) {
      requesParentsDistributePercent(superDatas);
    } else if (data?.level == 6) {
      requesParentsDistributePercent(megaDatas);
    } else if (data?.level == 5) {
      requesParentsDistributePercent(starDatas);
    } else if (data?.level == 4) {
      requesParentsDistributePercent(premiumDatas);
    } else if (data?.level == 3) {
      requesParentsDistributePercent(influencerDatas);
    }
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  /**
   * api
   */
  const requestManagerPartnerLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/manager/partner";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code === 0) {
        setData(response.data.data);
        setSuperDatas(response.data.data.superUsers);
        setMegaDatas(response.data.data.megaUsers);
        setStarDatas(response.data.data.starUsers);
        setPremiumDatas(response.data.data.premiumUsers);
        setInfluencerDatas(response.data.data.influencerUsers);
      }
    } catch (error) {}
  };

  const requesParentsDistributePercent = async (
    parentsDistributePercents: any[]
  ): Promise<void> => {
    console.log(parentsDistributePercents);
    var accessToken = localStorage.getItem("accessToken");
    const requestParentsDistributePercents = parentsDistributePercents.map((item: any) => ({
      no: item.no,
      nowFee: item.nowFee,
      parentsDistributePercent: item.parentsDistributePercent,
    }));
    try {
      const response = await axios.post(
        hostUrl + "/manager/distributePercent",
        {
          parentsDistributePercents: requestParentsDistributePercents,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
        }
      );
      console.log(response);
      if (response.data.code === 0) {
        showAlertPopup("Success");
      } else {
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <section className="w-full h-full manager overflow-auto">
        <HeaderManager />
        <section className="main">
          <h1 data-lang="title_partner">Partner Status</h1>
          <section className="partner">
            {data?.level == 7 && (
              <section>
                <ManagerPartnerInfo
                  todaySettlement={
                    data?.todaySettlement == null
                      ? "0"
                      : formatNumberWithCommas(data?.todaySettlement)
                  }
                  data={data}
                />
                <ManagerPartnerList
                  title="Super Influencer"
                  data={data}
                  members={superDatas}
                  bShowDistributions={true}
                  onFeeChange={handleFeeChange}
                />
                <button data-lang="btn_fee_apply" onClick={doDistributePercent}>
                  Fees Apply
                </button>
                <button data-lang="btn_fee_history">Fees Change History</button>
                <ManagerPartnerList
                  title="Mega Influencer"
                  data={data}
                  members={data?.megaUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
                <ManagerPartnerList
                  title="Star Influencer"
                  data={data}
                  members={data?.startUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
                <ManagerPartnerList
                  title="Premium Influencer"
                  data={data}
                  members={data?.premiumUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
                <ManagerPartnerList
                  title="Influencer Influencer"
                  data={data}
                  members={data?.influencerUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
              </section>
            )}
            {data?.level == 6 && (
              <section>
                <ManagerPartnerInfo
                  todaySettlement={
                    data?.todaySettlement == null
                      ? "0"
                      : formatNumberWithCommas(data?.todaySettlement)
                  }
                  data={data}
                />
                <ManagerPartnerList
                  title="Mega Influencer"
                  data={data}
                  members={data?.megaUsers}
                  bShowDistributions={true}
                  onFeeChange={handleFeeChange}
                />
                <button data-lang="btn_fee_apply" onClick={doDistributePercent}>
                  Fees Apply
                </button>
                <button data-lang="btn_fee_history">Fees Change History</button>
                <ManagerPartnerList
                  title="Star Influencer"
                  data={data}
                  members={data?.startUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
                <ManagerPartnerList
                  title="Premium Influencer"
                  data={data}
                  members={data?.premiumUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
                <ManagerPartnerList
                  title="Influencer Influencer"
                  data={data}
                  members={data?.influencerUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
              </section>
            )}
            {data?.level == 5 && (
              <section>
                <ManagerPartnerInfo
                  todaySettlement={
                    data?.todaySettlement == null
                      ? "0"
                      : formatNumberWithCommas(data?.todaySettlement)
                  }
                  data={data}
                />
                <ManagerPartnerList
                  title="Star Influencer"
                  data={data}
                  members={data?.startUsers}
                  bShowDistributions={true}
                  onFeeChange={handleFeeChange}
                />
                <button data-lang="btn_fee_apply" onClick={doDistributePercent}>
                  Fees Apply
                </button>
                <button data-lang="btn_fee_history">Fees Change History</button>
                <ManagerPartnerList
                  title="Premium Influencer"
                  data={data}
                  members={data?.premiumUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
                <ManagerPartnerList
                  title="Influencer Influencer"
                  data={data}
                  members={data?.influencerUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
              </section>
            )}
            {data?.level == 4 && (
              <section>
                <ManagerPartnerInfo
                  todaySettlement={
                    data?.todaySettlement == null
                      ? "0"
                      : formatNumberWithCommas(data?.todaySettlement)
                  }
                  data={data}
                />
                <ManagerPartnerList
                  title="Premium Influencer"
                  data={data}
                  members={data?.premiumUsers}
                  bShowDistributions={true}
                  onFeeChange={handleFeeChange}
                />
                <button data-lang="btn_fee_apply" onClick={doDistributePercent}>
                  Fees Apply
                </button>
                <button data-lang="btn_fee_history">Fees Change History</button>
                <ManagerPartnerList
                  title="Influencer Influencer"
                  data={data}
                  members={data?.influencerUsers}
                  bShowDistributions={false}
                  onFeeChange={handleFeeChange}
                />
              </section>
            )}
            {data?.level == 3 && (
              <section>
                <ManagerPartnerInfo
                  todaySettlement={
                    data?.todaySettlement == null
                      ? "0"
                      : formatNumberWithCommas(data?.todaySettlement)
                  }
                  data={data}
                />
                <ManagerPartnerList
                  title="Influencer"
                  data={data}
                  members={data?.influencerUsers}
                  bShowDistributions={true}
                  onFeeChange={handleFeeChange}
                />
                <button data-lang="btn_fee_apply" onClick={doDistributePercent}>
                  Fees Apply
                </button>
                <button data-lang="btn_fee_history">Fees Change History</button>
              </section>
            )}
          </section>
        </section>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
    </div>
  );
};

export default Partner;
