import React, { useState } from "react";
import Header from "../components/ui/Header";
import { Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Footer from "../components/ui/Footer";

const Legal = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <div className="w-full h-full text-white">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full max-w-[1024px] mx-auto">
        <div className="relative content-top content-padding">
          <h1 className="font-bold text-[1.5rem] text-white text-center">Legal Statement</h1>
        </div>
        {/* Legal Statement Section */}
        <section className="p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">
            Please read the Legal Statement carefully.
          </h2>

          {/* Article 1 */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Article 1</h3>
            <p>
              This website is intended to provide to its best efforts financial products and a
              trading platform of professional and international standards for the majority of
              global digital assets enthusiasts and investors, on the precondition that it does not
              violate any of the relevant laws and regulations of Hong Kong. It is prohibited to use
              this Website for the purpose of engaging in such illegal trading activities as money
              laundering, smuggling, and commercial bribery; where any account is found to involve
              any of the aforementioned illegal activities, this Website will freeze the account and
              immediately report such account to the competent authority.
            </p>
          </div>

          {/* Article 2 */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Article 2</h3>
            <p>
              Where a competent authority, on the strength of applicable investigation certificate,
              requests this Website to cooperate with the investigation relating to any designated
              user, or if the account of the user is subject to such measures as closure, freezing,
              or transfer, this Website will, as is required by the competent authority, assist such
              authority by providing corresponding data relating to the account user, or carrying
              out the corresponding operation as is required by the competent authority; for any
              disclosure of the user’s privacy, failure in the operation of the account and any such
              losses arising therefrom, this Website will not be held liable in any manner
              whatsoever.
            </p>
          </div>

          {/* Article 3 */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Article 3</h3>
            <p>
              Where a user of this Website violates any provision in this statement and consequently
              violates any clause of relevant laws of Hong Kong, this Website, as a service
              provider, is obliged to improve the rules and services of this platform. However, this
              Website does not have either the motivation for violating any law of Hong Kong, nor
              has this Website committed any actual breach of any of the relevant laws of Hong Kong,
              and will not assume any joint or several liability for the acts of the user.
            </p>
          </div>

          {/* Article 4 */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Article 4</h3>
            <p>
              Any person that logs into this Website or uses this Website directly or indirectly
              shall be deemed as having accepted the restriction of this Website voluntarily.
            </p>
          </div>

          {/* Article 5 */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Article 5</h3>
            <p>
              Any matter not covered in this statement shall be handled in accordance with relevant
              laws and regulations of Hong Kong. Where this statement is in conflict with relevant
              laws and regulations of Hong Kong, the latter shall prevail.
            </p>
          </div>
        </section>
        <Footer />
      </section>
    </div>
  );
};

export default Legal;
