import React, { useState } from "react";

interface Props {
  tradeFutures: any;
}

const TradeFuturesList: React.FC<Props> = ({ tradeFutures }) => {

  const headers = [
    "Time",
    "Symbol",
    "Order",
    "Position",
    "Entry Price",
    "Close Price",
    "Size",
    "Fee",
    "Adjustment"
  ];

  return (
    <div className="w-full h-auto mt-5 px-2 pb-5">
      <div className="pb-3 overflow-x-auto">
        <table className="min-w-full overflow-hidden">
          <thead>
            <tr className="text-[0.8rem] leading-normal text-gray-400">
              {headers.map((header, index) => (
                <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light text-gray-600">
            {tradeFutures.map((tradeFuture: any, index: number) => (
              <tr key={index} className="text-[0.8rem] text-white"></tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TradeFuturesList;
