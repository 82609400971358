import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import "../assets/css/Common.css";
import CustomSelect from "../components/ui/CustomSelect";
import WithdrawList from "../components/ui/WithdrawList";
import axios from "axios";

const WithdrawHistory = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [withdraws, setWithdraws] = useState([]);

  useEffect(() => {
    requestWithdrawLookup();
  }, []);
  
  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

    /**
   * api
   */
    const requestWithdrawLookup = async (): Promise<void> => {
      var accessToken = localStorage.getItem("accessToken");
      var requestUrl = hostUrl + "/withdraw/lookup";
      try {
        const response = await axios.get(requestUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        //console.log(response);
        if (response.data.code == 0) {
          setWithdraws(response.data.data.withdraws);
        } else {
          if (response.data.code === 40000) {
            localStorage.removeItem("accessToken");
            window.location.href = "/login";
          }
        }
      } catch (error) {}
    };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="content-top content-padding">
          <h1 className="font-bold text-xl text-white">Withdraw History</h1>
          {/*
            List
            */}
          <section className="mt-5 pb-5">
            <WithdrawList withdraws={withdraws} />
          </section>
        </div>
      </section>
    </div>
  );
};

export default WithdrawHistory;
