import { useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import "../assets/css/UnderConstruction.css";

const UnderConstruction = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <section className="check">
          <div className="wrapper">
            <div></div>
            <div></div>
          </div>
          <h1>
            This page is being elegantly prepared.
            <br />
            We look forward to sharing it with you soon.
          </h1>
        </section>
      </section>
    </div>
  );
};

export default UnderConstruction;
