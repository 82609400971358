import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import "../assets/css/Faq.css";
import Footer from "../components/ui/Footer";

const Faq = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const faqElements = document.querySelectorAll(".faq .q");

    faqElements.forEach((faqElement) => {
      faqElement.addEventListener("click", (event) => handleToggleCollapse(event));
    });

    // Clean up event listeners on component unmount
    return () => {
      faqElements.forEach((faqElement) => {
        faqElement.removeEventListener("click", (event) => handleToggleCollapse(event));
      });
    };
  }, []); // Empty dependency array ensures effect runs only once on mount

  const handleToggleCollapse = (event: Event) => {
    const target = event.target as HTMLElement;
    const nextElement = target.nextElementSibling as HTMLElement | null;
    if (nextElement) {
      nextElement.classList.toggle("collapsed");
    }
  };

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="relative content-top content-padding">
          <h1 className="font-bold text-[1.5rem] text-white text-center">Faq</h1>
          <section className="faq">
            <div className="q">What is USDT perpetual contract?</div>
            <div className="a collapsed">
              <h2>
                1. What is USDT perpetual contract? How does it differ with Inverse perpetual
                contract?{" "}
              </h2>
              <p>
                USDT perpetual contract is a linear contract. The margin used for a linear contract
                is USDT. On the other hand, an inverse contract means if a trader would like to
                trade BTC/ETH/XRP/EOS contract, the underlying cryptocurrency has to be used as the
                margin to trade the respective contract. As compared to Inverse perpetual contract,
                USDT perpetual contract consists of the following:{" "}
              </p>
              <ul>
                <li>
                  The calculation of margin and P&L of USDT perpetual contract is more direct as
                  compared to Inverse perpetual contract. When trading 1 BTC and the price moves by
                  100 USDT, the profit/loss of the trader will be 100 USDT. The P&L chart of the
                  USDT contracts will be a linear curve.
                </li>
                <li>
                  Inverse perpetual contract is traded based on the underlying cryptocurrency.
                  Traders need to hold a much volatile BTC/ETH/EOS/XRP as margin. Hence, even if
                  traders choose not to trade, holding the cryptocurrency itself involves risks. On
                  the other hand, USDT perpetual contract uses stablecoin as margin and thus,
                  traders do not have to hedge their position to avoid the risk of holding the
                  cryptocurrency.
                </li>
              </ul>

              <h2>2. How to deposit USDT? </h2>
              <p>
                Traders can navigate to My Assets page and click on USDT deposit. ViBex accepts
                USDT deposits from Omni based on the Bitcoin network, TRC20 and ERC20 from the
                Ethereum network. The arrival time of the deposit is generally about ten minutes.
                Traders can also use the asset exchange function to exchange other cryptocurrencies
                such as BTC or ETH to USDT for trading. For specific steps on how to make a deposit,
                please click here.
              </p>

              <h2>
                3. What is the highest leverage supported by USDT contracts? Does it support
                hedging?{" "}
              </h2>
              <p>
                Different USDT contracts have different highest leverage, please refer to Risk Limit
                (USDT Contract).
              </p>
              <p>
                Yes, USDT contracts support hedging. Traders can use different leverage on long and
                short position so as to allow more flexibility in managing long-term and short-term
                speculative position.{" "}
              </p>

              <h2>4. Is there a fee applicable when closing a hedged position?</h2>
              <p>
                Currently, ViBex does not support internal matching of hedging positions. When a
                hedged position is closed by a trader, the trading fee is determined on whether it
                is a taker or a maker order.
              </p>

              <h2>5. Will a hedged position be subjected to liquidation? </h2>
              <p>
                Under isolated mode, the hedged positions are independent and not related, hence the
                positions can be liquidated individually. Under cross mode, fully hedged positions
                will never be liquidated. When the positions are not fully hedged, only the excess
                contracts that are not fully hedged may be subjected to liquidation, and the
                positions that are hedged will not be affected.{" "}
              </p>

              <h2>6. Will a hedged position be subjected to Auto-deleveraging (ADL)? </h2>
              <p>
                The same logic for liquidation applies for ADL. Under isolated margin, the
                individual position has the risk of being ADL. Under cross margin, fully hedged
                positions will not be chosen for ADL. When the positions are not fully hedged, only
                the excess contracts that are not fully hedged may be subjected to ADL, and the
                positions that are hedged will not be affected. The ADL ranking lights indicate the
                risk of being chosen for ADL where each light lit represents a 20% increment in ADL
                ranking. When the positions are hedged, the system will compare both the Buy/Long
                and Sell/Short ADL ranking and reflects the higher ranking.
              </p>

              <h2>
                7. Is it possible to switch back and forth between isolated margin mode and cross
                margin mode?
              </h2>
              <p>
                When a trader switches a position from isolated margin mode to cross margin mode, if
                he holds long and short positions of the contract simultaneously, both positions
                will be switched to cross margin mode. When a trader tries to switch a position from
                cross margin mode to isolated margin mode, he can only do so when he holds either a
                long or short position or no position. If he holds both long and short positions of
                the contract simultaneously, cross margin mode cannot be switched to isolated margin
                mode.
              </p>

              <h2>
                8. What are the differences between Auto Margin Replenishment (AMR) and Cross margin
                mode?
              </h2>
              <p>
                Unlike cross margin mode which uses all the available balance to calculate the
                liquidation price, traders will be notified when AMR is triggered and this allows
                traders to have time to better manage their position.{" "}
              </p>
              <p>
                However, under isolated margin mode, both long and short position remains
                independent and can be liquidated in both directions.{" "}
              </p>

              <h2>9. What is Risk Limit? </h2>
              <p>
                The concept of dynamic leverage is used for ViBex's Risk Limit. This means the
                larger the contract value traders hold, the lower the maximum leverage allowed. In
                other words, the initial margin requirement incrementally goes up by a fixed
                percentage at every specific increase in contract value level. Each trading pairs
                have its specific maintenance margin base rate and the margin requirements will
                increase or decrease accordingly as risk limit changes.
              </p>
              <p>For more details, please refer to Risk Limit (USDT Contract)</p>

              <h2>
                10. Can I use unrealized profit to open new position or withdraw the unrealized
                profit？
              </h2>
              <p>
                No, under cross margin mode, the unrealized P&L can only be realized upon position
                closing. The unrealized profit will not increase the available balance, hence it
                cannot be utilized to open new position or make a withdrawal.
              </p>
              <p>
                Unrealized profit will not be shared across different trading pairs, but only the
                same trading pairs under cross margin mode. For example, the unrealized profit from
                ETHUSDT cannot be used to support the BTCUSDT position losses. However the
                unrealized P&L of the same trading pair, such as BTCUSDT long and BTCUSDT short,
                will still be used to support each other under cross margin mode.{" "}
              </p>

              <h2>11. Is the insurance fund of USDT contracts shared among all coins?</h2>
              <p>
                Unlike inverse contract which uses the underlying coin as settlement, all USDT
                contracts will use USDT as settlement. Hence, the USDT insurance fund is shared
                among all contracts.{" "}
              </p>

              <h2>
                12. How to withdrawal USDT? Which method of withdrawal does ViBex support?{" "}
              </h2>
              <p>
                ViBex supports withdrawal via ERC20, TRC 20 and do not support Omni. For the
                steps on how to make a withdrawal, please click here.
              </p>

              <h2>
                13. What is the maximum and minimum order quantity of different trading pairs?
              </h2>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Minimum Order Quantity</th>
                    <th>Maximum Order Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>BTCUSDT</td>
                    <td>0.001 BTC</td>
                    <td>100 BTC</td>
                  </tr>
                </tbody>
              </table>
              <p>
                To have a position size that is larger than the maximum order quantity, traders can
                achieve this by placing multiple orders.{" "}
              </p>
            </div>

            <div className="q">Initial Margin (USDT Contract)</div>
            <div className="a collapsed">
              <p>
                Initial Margin is the amount of collateral required to open a position for Leverage
                trading. The leverage used is directly related to the initial margin used to
                maintain the position. The higher the leverage, the lower the initial margin
                required.
              </p>
              <p>
                To calculate the initial margin required for USDT Contracts, multiply the order
                value with the initial margin rate. The initial margin rate depends on the leverage
                used.{" "}
              </p>
              <p>Initial Margin = Contract size x Entry Price / Leverage</p>
              Example:
              <br />
              Trader place a long entry of 1 BTC at USDT 10,000 with 50x leverage. <br />
              Initial Margin=(1x10,000)/50 =200 USDT
              <br />
              <p>
                The initial margin used for a trade can be found on the "Positions" tab. Please be
                noted that the initial amount shown here includes the expected taker fee to close
                the position. In the Isolated Margin Mode, the Initial Margin used can be adjusted
                by clicking on the "Pen" symbol icon. By adjusting the Initial Margin used, this
                will affect the liquidation price of the position. For more details regarding
                adjusting the initial margin, please click here.{" "}
              </p>
            </div>

            <div className="q">Maintenance Margin (USDT Contract)</div>
            <div className="a collapsed">
              <p>
                Maintenance Margin is the minimum margin required to continue holding a position.
              </p>
              <p>
                It will increase or decrease according to the trader's selected risk limit. By
                default, all risk limits start at the lowest maintenance margin level inside each
                trading pair's risk limit table.{" "}
              </p>
              <p>
                Liquidation occurs when the isolated margin for the position is less than its
                maintenance margin level.
              </p>
              <p>Order Value = Contract Size x Entry Price</p>
              <p>Maintenance Margin = Maintenance Margin Rate x Order Value</p>
              <p>
                The maintenance margin rate (MMR) required for a position is based on the selected
                margin level requirements determined by its position value.{" "}
              </p>
              Example:
              <br />
              Trader place a long position of 1 BTC at USDT 10,000 with 50x leverage (Isolated
              Margin).
              <br />
              <br />
              Initial Margin = 1 x 10,000 x 1/50 = 200 USDT
              <br />
              Maintenance Margin = 1 x 10,000 x 0.5% = 50 USDT
              <br />
              <p>
                This means that this position could take an unrealized loss (Mark Price) of up to
                150 USDT (200USDT – 50USDT) before liquidation takes place.{" "}
              </p>
              <p>
                You may refer to the Maintenance Margin basic value under 'Contract Details' located
                on the main trading page.
              </p>
            </div>

            <div className="q">P&L calculations (USDT Contract)</div>
            <div className="a collapsed">
              <p>
                Regardless of any trades, it is important to understand how P&L is calculated before
                entering one. In sequential order, traders need to understand the following
                variables in order to accurately calculate their P&L.{" "}
              </p>
              <ol>
                <li>Average Entry Price of position </li>
                <li>Unrealized P&L and unrealized P&L% of the position </li>
                <li>Closed P&L </li>
              </ol>
              <h2>1) Average Entry Price (AEP) of position</h2>
              <p>
                In ViBex, whenever traders add on to their position via new orders, AEP will
                change.{" "}
              </p>
              <p>
                For example: Trader A holds an existing BTCUSDT open buy position of 0.5 qty with an
                entry price of USD 5000. After an hour, Trader A decided to increase his buy
                position by opening an additional 0.3 qty with an entry price of USD 6,000.
              </p>
              <p>Below shows how the formula for AEP and the computation steps:</p>
              <h3>
                Average entry price = Total contract value in USDT/Total quantity of contracts
              </h3>
              <h3>
                Total contract value in USDT = ( (Quantity1 x Price1) + (Quantity2 x Price2)...)
              </h3>
              By using the figures above:
              <br />
              Total contract value in USDT <br />
              = ( (Quantity1 x Price1) + (Quantity2 x Price2) )<br />
              = ( (0.5 x 5000) + (0.3 x 6000) )<br />
              = 4300
              <br />
              Total quantity of contracts
              <br />
              = 0.5 + 0.3
              <br />
              = 0.8 BTC
              <br />
              Average Entry Price
              <br />
              = 4300 / 0.8
              <br />
              = 5375
              <br />
              <h2>2) Unrealized P&L </h2>
              <p>
                Once an order is successfully executed, an open position and its real-time
                unrealized P&L will be shown inside the positions tab.
              </p>
              <p>
                Depending on which side of the trade you are in, the formula used to calculate the
                unrealized P&L will differ.
              </p>
              <p>For long position:</p>
              <p>For example:</p>
              <p>
                Trader B holds an existing BTCUSDT open buy position of 0.2 qty with an entry price
                of USD 7000. When the Last Traded Price inside the order book is showing USD 7500,
                the unrealized P&L shown will be 100 USDT.
              </p>
              <h3>Unrealized P&L = Contract Qty x (Last Traded Price - Entry Price)</h3>
              = 0.2 x (7500 - 7000)
              <br />
              = 100 USDT
              <br />
              For short position
              <br />
              <p>
                For example: Trader C holds an existing BTCUSDT open sell position of 0.4 qty with
                an entry price of USD 6000. When the Last Traded Price inside the order book is
                showing USD 5,000, the unrealized P&L shown will be 400 USDT.
              </p>
              <h3>Unrealized P&L = Contract Qty x (Entry Price - Last Traded Price)</h3>
              = 0.4 x ( 6000 - 5000)
              <br />
              = 400 USDT
              <br />
              <p>Note: </p>
              <ol type="a">
                <li>
                  In USDT contracts, your P&L is also settled in USDT. This is opposite to inverse
                  contracts where P&L is settled depending on the coin being traded (ex. BTCUSD
                  inverse is settled in BTC).
                </li>
                <li>
                  When the price movement is by a certain price (example USD 1000) in the profitable
                  or non-profitable direction, assuming position size of 1 BTC, this means that a
                  trader will gain or lose USD 1000 respectively.{" "}
                </li>
                <li>
                  Increasing leverage does not directly multiply the profits/losses directly.
                  Instead, profits and losses are determined by the position size and price
                  movement. In short,
                  <ul>
                    <li>
                      The higher the leverage, the lower the margin collateral needed to open your
                      position
                    </li>
                    <li>The larger the contract quantity, the bigger the profits/losses</li>
                    <li>
                      The larger the price movement relative to entry price, the bigger the
                      profits/losses
                    </li>
                  </ul>
                </li>
                <li>
                  The default unrealized P&L is shown based on Last Traded Price. When hovering a
                  mouse cursor on top of the figure, the unrealized P&L will change and show an
                  unrealized P&L based on Mark Price
                </li>
                <li>
                  Last but not least, unrealized P&L does not factor in any trading or funding fees
                  which traders may have received/paid out in the process of opening and holding the
                  position.
                </li>
              </ol>
              <h2>2A) Unrealized P&L%</h2>
              <p>
                Unrealized P&L% basically shows the Return on Investment (ROI) of the position in
                its percentage form. Similar to Unrealized P&L, the figure shows changes depending
                on the movement of Last Traded Price. As such, the Unrealized PNL% or ROI formula is
                below.
              </p>
              <h3>Unrealized P&L% = [ Position's unrealized P&L / Position Margin ] x 100%</h3>
              <h3>Position Margin = Initial margin + Fee to close</h3>
              <p>
                Using Trader B as an example, Trader B holds an existing BTCUSDT open buy position
                of 0.2 qty with an entry price of USD 7000. When the Last Traded Price inside the
                order book is showing USD 7500, the unrealized P&L shown will be 100 USDT. Assuming
                the leverage used is 10x.{" "}
              </p>
              <p>Based on our earlier calculation, the position's unrealized P&L = 100 USDT</p>
              <p>Initial margin = (Qty x Entry price) / leverage = (0.2 x 7000) /10 = 140 USDT</p>
              <p>
                Fee to close = Bankruptcy price x Qty x 0.075% = 6300 x 0.2 x 0.075% = 0.945 USDT
              </p>
              <p>Unrealized P&L% = [ 100 USDT / ( 140 USDT + 0.945 USDT ) ] x 100% = 70.95%</p>
              <p>Note: </p>
              <ol type="a">
                <li>
                  Some traders may misunderstood this but adjustments to increase leverage does not
                  increase your unrealized profits. Instead, traders will see an increase in
                  unrealized P&L% due to a reduction in your position margin and not because of an
                  increase in actual profits. Using Trader B as an example again, notice that
                  regardless if leverage is 10x, 5x or 20x, the unrealized P&L remains the same.
                  <ul>
                    <li>
                      If Trader B uses the same 10x leverage, his unrealized P&L = 100 USDT,
                      unrealized P&L% = 70.95%
                    </li>
                    <li>
                      If Trader B reduces the leverage to 5x, his unrealized P&L = 100 USDT,
                      unrealized P&L% = 35.71%{" "}
                    </li>
                    <li>
                      If Trader B increases the leverage to 20x his unrealized P&L = 100 USDT,
                      unrealized P&L% = 142.85%
                    </li>
                  </ul>
                </li>
                <li>
                  For cross margin mode, the position margin will be calculated base on the leverage
                  used for the particular coin.{" "}
                </li>
              </ol>
              <h2>3) Closed P&L </h2>
              <p>
                When traders finally closed their position, the P&L becomes realized and is recorded
                inside the Closed P&L tab within the Assets page. Unlike unrealized P&L, there are
                some major differences in the calculation. Below summarizes the differences between
                the unrealized P&L and closed P&L.{" "}
              </p>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Calculation of Unrealized P&L</th>
                    <th>Calculation of Closed P&L</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Position Profit and Loss (P&L)</td>
                    <td>YES</td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>Trading Fee(s)</td>
                    <td className="red">NO</td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>Funding Fee(s)</td>
                    <td className="red">NO</td>
                    <td>YES</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Therefore, assuming full closing of the entire position, the formula for calculating
                Closed P&L is as follows{" "}
              </p>
              <h3>
                Closed P&L = Position P&L - Fee to open - Fee to close - Sum of all funding fees
                paid/received
              </h3>
              <p>
                Using Trader C as an example, Trader C holds an existing BTCUSDT open sell position
                of 0.4 qty with an entry price of USD 6000. When the Last Traded Price inside the
                order book is showing USD 5000, trader C decided to close the entire position via
                Close by Market function. Assuming that Trader C also opened the position via a
                market order and funding fees totaling 2.10 USDT was paid out while holding the
                position.{" "}
              </p>
              <p>Based on our earlier calculation, the position's P&L = 400 USDT received</p>
              <p>Fee to open = Qty x Entry price x 0.075% = 1.80 USDT paid out</p>
              <p>Fee to close = Qty x Exit price x 0.075% = 1.50 USDT paid out</p>
              <p>Sum of all funding fees paid/received = 2.10 USDT paid out</p>
              <p>Closed P&L = 400 - 1.80 - 1.50 - 2.10 = 394.60 USDT</p>
              <p>Note: </p>
              <ol type="a">
                <li>
                  The above example only applies when the entire position is opened and closed via a
                  single order in both directions.{" "}
                </li>
                <li>
                  {" "}
                  For partial closing of positions, Closed P&L will pro-rate all fees (fee to open
                  and funding fee(s)) according to percentage of position partially closed and use
                  the pro-rated figure to compute the Closed P&L
                </li>
              </ol>
            </div>

            <div className="q">Order Cost (USDT Contract)</div>
            <div className="a collapsed">
              <p>
                At the Order Confirmation Window and Order Zone, the ‘Order Cost’ is the total
                margin needed to open a particular position. It is calculated using the Initial
                Margin plus a 2-way taker fee. The actual fee charged/received depends on the
                execution type.
              </p>
              <p>Order Cost = Initial Margin + Two-Way Taker Fee</p>
              <p>Fee to open = (Quantity of contracts * Order Price) x Taker fee</p>
              <p>
                Fee to close = (Quantity of contracts * Bankruptcy Price derived from Order Price) x
                Taker fee
              </p>
              <p>Bankruptcy Price for long position = Entry Price x (1 - Initial Margin Rate)</p>
              Example:
              <br />
              Trader place a long entry of 1BTC at USD8000 with 50x leverage.
              <br />
              <br />
              Initial Margin = (1BTC*8000) / 50
              <br />
              = 160USDT <br />
              <br />
              Fee to open = 1BTC x 8000 x 0.075%
              <br />
              = 6USDT
              <br />
              <br />
              Bankruptcy Price = 8000 x (1 – 0.02)
              <br />
              = USD7840
              <br />
              <br />
              Fee to close = 1BTC x 7840 x 0.075%
              <br />
              = 5.88USDT
              <br />
              <br />
              <p>For more details about the calculation of bankruptcy price, please click here. </p>
              Order Cost = 160USDT + 6USDT + 5.88USDT
              <br />= 171.88 USDT
            </div>

            <div className="q">Adjust Margin on position (USDT Contract)</div>
            <div className="a collapsed">
              <p>
                Traders are allowed to add/reduce the margin to a position when under isolated
                margin mode.{" "}
              </p>
              <p>
                This can be done through the positions tab, by clicking on the pencil in the
                following screenshot and entering the new initial margin to be used for the
                position.
              </p>

              <p>
                For USDT contract, when the margin is added to the position, the leverage used for
                opening the position and the leverage in the order zone will not be affected. The
                liquidation price of the position will also be recalculated base on the new position
                margin for the position. Traders can preview the after-adjusted liquidation price
                before they confirm the adjustment.
              </p>
              <p>
                The following are 3 scenarios that will affect the extra margin that was added to
                the position.
              </p>

              <h2>Scenario 1</h2>
              <p>
                After additional margins were added to the position, Trader A changes the leverage
                at the order zone, the initial margin of the position will be reset to the initial
                margin required for the new leverage. Any extra margin added to the position before
                will be removed.{" "}
              </p>
              <p>
                **Please be noted that traders are only allowed to lower the leverage when there is
                a sufficient available margin and set higher leverage only when the liquidation will
                not be triggered immediately. Otherwise, the leverage changing operation will be
                rejected.
              </p>

              <h2>Scenario 2</h2>
              <p>
                After adding an extra margin to the position. Add a new position with the same
                trading direction. The additional margin added will be applied to the total
                position.
              </p>
              <p>Example:</p>
              <ol>
                <li>
                  A trader has a 1BTC long position with liquidation price at 9,500 USDT. Entry
                  price 10,000USDT.
                </li>
                <li>
                  1000USDT has been added to the position, which lowers the liquidation price to
                  8500USDT.
                </li>
                <li>
                  Trader adds a new long position with a contract size of 1BTC at 10,000USDT. Now
                  the total contract size is 2BTC.
                </li>
                <li>
                  The added 1000USDT will be shared by the total 2BTC position. The liquidation
                  price will move up to 9000USDT.
                </li>
              </ol>

              <h2>Scenario 3</h2>
              <p>
                After adding an extra margin to the position. Partially close the position. The
                extra margin added will be reduced proportionally according to the percentage of
                partial closure. The liquidation price of the position will not be changed after a
                partial position closure.{" "}
              </p>
              <p>Example:</p>
              <ol>
                <li>
                  A trader has a 1BTC long position with liquidation price at 9,500 USDT. Entry
                  price 10,000USDT.
                </li>
                <li>
                  1000USDT has been added to the position, which lowers the liquidation price to
                  8,500 USDT.
                </li>
                <li>
                  50% of the position (0.5BTC) is partially closed. The remaining contract size is
                  0.5BTC. The extra margin added will be reduced to 500 USDT(50%). The liquidation
                  price of the position will remain as 8,500 USDT.
                </li>
              </ol>
            </div>

            <div className="q">Introduction to Auto-Margin Replenishment (USDT Contract)</div>
            <div className="a collapsed">
              <p>
                Cross Margin mode is the default margin mode on ViBex. Auto-Margin Replenishment
                (AMR) is only available under isolated margin mode, where the margin that you placed
                into a position is isolated from your account balance. ViBex will not
                automatically extract any additional margin from your available balance to your
                position. That also means, under this mode, the maximum amount you will loss from
                liquidation is the initial margin you placed for the particular position.
              </p>

              <p>
                Isolated Margin is useful for speculative positions. By isolating the margin, users
                can have better risk control to limit the maximum loss to a position when the market
                is headed towards an unfavorable direction.
              </p>

              <p>
                AMR is a function that allows traders to automatically add margin to existing open
                positions in order to avoid liquidation. Once AMR is enabled, every time your margin
                level is about to reach the maintenance margin level, ViBex will replenish the
                margin from your available balance. The amount drawn would be equivalent to the
                initial margin that is currently used for this position. If there is insufficient
                available balance, ViBex will utilize all of the remaining balance to replenish
                the margin of the position. Once margin is added to a position, you may also notice
                that the liquidation price will be further away from the Mark Price.
              </p>

              <p>
                To activate/deactivate AMR, users can turn on the “AutoMargin” mode under the
                "Position" section.
              </p>

              <p className="red">*Note:</p>

              <p className="red">
                When AMR is enabled, the lowest leverage the position will go is limited to 1x. More
                margin will not be added when a position is already at 1x leverage even if there is
                excess available margin.
              </p>

              <h2>Example: Auto-Margin Replenishment Process</h2>

              <p>
                A trader has an available balance of 10000 USDT. The current price of BTC is 8,000
                USDT. He opens a Long position for 5 BTCUSDT contracts using an initial margin of
                4000 USDT with 10x leverage. With the maintenance margin rate at 0.5%, the
                liquidation price is calculated to be at 7,240 USDT. The remaining available balance
                would be 6000 USDT.
              </p>

              <p>
                Mark Price falls to 7,240 USDT and reaches the Liquidation Price, Auto Margin
                Replenishment will automatically take over the process and prevent the position from
                being liquidated. It will use an amount from the available balance to replenish the
                margin back up to 4000 USDT, leaving 2000USDT left in the available balance. The new
                liquidation price would now be 6,440 USDT, and the initial margin calculated for
                this position would now be at 8000 USDT.
              </p>

              <p>
                Should the price of BTCUSDT continue to fall and reach the new liquidation price of
                6,440 USDT, Auto Margin Replenishment will once again come into effect but this
                time, only replenishing the position with the remaining 2000 USDT left in the
                available balance. The new liquidation price of the position would now be at 6,040
                USDT.
              </p>

              <p>
                However, as there isn’t any available balance left in the account, should the price
                hit 6,040 USDT, the position would finally be liquidated as Auto Margin
                Replenishment would not automatically come into effect anymore to prevent the
                position from being liquidated.
              </p>

              <p className="red">*Notes</p>
              <ol className="red">
                <li>
                  When liquidation is triggered, the system will first cancel all unfulfilled active
                  orders in the account in order to free up more available margin and use this to
                  try and avoid liquidation
                </li>
                <li>Please note that the calculations above do not include any trading fees</li>
              </ol>

              <h2>Example: Difference between Cross margin and isolated margin with AMR</h2>

              <p>
                Unlike cross margin mode which uses all the available balance to calculate the
                liquidation price, traders will be notified when AMR is triggered and this allows
                traders to have time to better manage their position.{" "}
              </p>

              <p className="red">Notes</p>

              <p className="red">
                *under isolated margin mode, both long and short position remains independent and
                can be liquidated in both directions.
              </p>
            </div>

            <div className="q">Liquidation Process (USDT Contract)</div>
            <div className="a collapsed">
              <p>
                ViBex uses mark price to avoid liquidation caused by low liquidity or market
                manipulation.{" "}
              </p>

              <p>
                Under isolated margin, when position margin decreases to the maintenance margin
                level, the position is liquidated. Please take note that if a trader holds long and
                short positions simultaneously under isolated mode, it may happen that both
                positions get liquidated under extreme market movement since long and short
                positions are independent.{" "}
              </p>

              <p>
                Under cross margin mode, when available balance decreases to 0 and position margin
                decreases to maintenance margin level, the position is liquidated. Please take note
                that when holding hedged positions of both long and short under cross margin mode,
                only the net long or net short position may be liquidated. A fully hedged position
                will not be liquidated.
              </p>

              <p>Larger positions may raise the margin requirement.</p>

              <p>
                When liquidation happens, ViBex uses partial liquidation to reduce the required
                maintenance margin to avoid full liquidation. The liquidation process is as follows.
              </p>

              <h2>Traders under the lowest margin tier</h2>
              <ol>
                <li>Cancel all active orders of this contract;</li>
                <li>
                  If it still doesn’t meet the maintenance margin requirement, that position will be
                  closed at the bankruptcy price by the Liquidation Engine.{" "}
                </li>
              </ol>

              <h2>Traders under second or higher margin tier </h2>

              <p>
                The liquidation engine will try to lower the margin tier to lower the margin
                requirement:{" "}
              </p>
              <ol>
                <li>
                  Cancel all active orders on this contract while retaining the existing position to
                  reduce the margin;
                </li>
                <li>
                  Submit a FillOrKill order of the difference between the current position value and
                  the lower margin tier value which satisfies the margin requirement, thus
                  preventing further liquidation;
                </li>
                <li>
                  If the position is still in liquidation, this position shall be taken over by the
                  liquidation engine at the bankruptcy price.
                </li>
              </ol>

              <h2>Illustrated Example:</h2>

              <p>
                If Trader A holds a 1.5 M USDT long position value + 1M BTC long Active Order value,
                it will correspond to the third tier of the margin system. When Mark Price reaches
                the liquidation price, the liquidation engine will take over the position.
              </p>

              <p>
                Cancel all Active orders, followed by reducing the current margin to the second tier
                at 2M USDT. In the process, this reduces the maintenance margin requirement and thus
                avoids liquidation by having a new liquidation price.{" "}
              </p>

              <h2>If Mark Price reaches the new liquidation price for the second time </h2>
              <ol>
                <li>
                  The liquidation engine will attempt to partially close the 500K USDT portion of
                  the existing 1.5M USDT position value in order to further reduce to the first tier
                  of 1M USDT to avoid fully liquidation.
                </li>
                <li>
                  If the system forecast that executing step (a) stated above is still unable to
                  prevent liquidation, the liquidation engine will take over the position and the
                  whole position will be liquidated.{" "}
                </li>
              </ol>

              <p>
                Lastly, at the lowest tiered margin, the entire position will directly be taken over
                by the liquidation engine to be liquidated and settled at its bankrupt price if Mark
                Price reaches the liquidation price again.{" "}
              </p>
            </div>

            <div className="q">Liquidation Price (USDT Contract)</div>
            <div className="a collapsed">
              <p>Liquidation is triggered when the Mark Price hits the Liquidation Price.</p>
              <h2>
                Liquidation Price (Isolated Margin, traders are allowed to add in the extra margin
                to position)
              </h2>
              <p>Initial Margin Rate = 1/Leverage</p>
              <p>Maintenance Margin Rate (MMR) is based on Tiered Margin.</p>
              <h2>For Buy/Long:</h2>
              <p>
                Liquidation Price = Entry Price * (1 - Initial Margin Rate + Maintenance Margin
                Rate) - Extra Margin Added/ Contract Size
              </p>
              <p>Example:</p>
              <p>
                Trader place a long entry of 1 BTC at 10,000 USDT with 50x leverage. Assuming no
                extra margin added.
              </p>
              <p>Liquidation Price = 10,000 USDT * (1 - 2% + 0.5%) = 9,850 USDT</p>
              <h2>For Sell/Short:</h2>
              <p>
                Liquidation Price = Entry Price * (1 + Initial Margin Rate - Maintenance Margin
                Rate) + Extra Margin Added/ Contract Size
              </p>
              <p>Example:</p>
              <p>
                Trader place a short entry of 1 BTC at 8,000USDT with 40x leverage. Assuming no
                extra margin added.
              </p>
              <p>Liquidation Price = 8,000 USDT * (1 + 2.5% - 0.5%) = 8160 USDT</p>
              <h2>Liquidation Price (Margin type: Cross Margin)</h2>
              <p>
                Comparing to Isolated Margin, Liquidation Price under Cross Margin mode might keep
                changing as the available balance will be affected by the other trading pairs. Under
                cross margin mode, the initial margin used for each position is isolated from the
                account balance but the remaining balance is shared. The available balance will be
                affected by the unrealized P&L that occurred by all existing positions. Liquidation
                only happened when the available balance = 0 and each position do not have enough
                maintenance margin respectively.
              </p>
              <p>Example 1(Only one unhedged position): </p>
              <p>
                Under Cross Margin, assuming trader A holding a 2BTC Long position at 10,000 USDT.
                The current available balance is 2,000 USDT, current mark price = 10,500USDT,
                unrealized profit(Mark Price) is 1,000 USDT.
              </p>
              Initial Margin = 2*10,000*1% = 200USDT
              <br />
              Maintenance Margin = 2 *10,000*0.5% = 100USDT
              <br />
              Available Balance = 2,000USDT
              <br />
              Total Sustainable Loss = Available Balance + Initial Margin - Maintenance Margin
              <br />
              = 2,000 + 200 -100 USDT
              <br />
              = 2,100 USDT
              <br />
              <p>
                With 2,100USDT, the position can sustain a price loss of 1,050 USDT (2,100/2).
                Therefore, the liquidation price of this position would be 9,450USDT (10,500-1050).{" "}
              </p>
              <p>Using the above logic, we can derive the liquidation price (Long) as below. </p>
              <p>
                Current Mark Price (MP) - Liquidation Price(LP) = [Available Balance (AB) + Initial
                Margin (IM) - Maintenance Margin (MM)]/ Exposed Position Size (EPS)
              </p>
              <h2>LP (Long) = MP - (AB+IM-MM)/EPS</h2>
              <h2>LP (Short) = MP+(AB+IM-MM)/EPS</h2>
              <p>Example 2 (One hedged position): </p>
              <p>
                Under Cross Margin, assuming trader B holding a 2BTC Long position with entry price
                at 10,000 USDT. The current available balance is 3,000 USDT, current Mark Price is
                9,500 USDT, unrealized loss for the Long position (Mark Price) is 1,000 USDT. At the
                same time, he is holding a Short position of 1BTC with entry price at 9,500 USDT.{" "}
              </p>
              <p>
                For the short position, it will never be getting liquidated as the Contract Size of
                Long position &gt; Contract Size of Short position, whenever the price goes up, the
                unrealized profit for long position is always greater than the unrealized loss of
                the short position.
              </p>
              <p>
                For the long position, we only need to consider the net exposure of the position
                abs(Long - Short ) = abs(2BTC - 1BTC) = 1BTC when calculating the liquidation price.{" "}
              </p>
              IM = 1*10,000*1% = 100USDT
              <br />
              MM = 1*10,000*0.5% = 50USDT
              <br />
              AB = 3,000 USDT
              <br />
              <p>LP (Long) = 9,500 - (3,000+100-50)/1 = 6,450 USDT</p>
            </div>

            <div className="q">Bankruptcy Price (USDT Contract)</div>
            <div className="a collapsed">
              <p>
                Bankruptcy Price is a price level that indicates you have lost all your initial
                margin.
              </p>
              <p>
                Upon liquidation, the liquidated position will be closed at the Bankruptcy Price,
                and this means that you have lost all your initial margin. If the liquidated
                position has its final liquidation price better than the bankruptcy price, the
                excess margin will be contributed to the Insurance Fund. Vice versa, if the
                liquidated position has its final liquidation price worse than the bankruptcy price,
                the Insurance fund will cover the loss gap.
              </p>

              <h2>Bankruptcy Price (Margin type: Isolated Margin)</h2>
              <p>For Buy/Long:</p>
              <p>Bankruptcy Price= Entry Price × (1 - Initial Margin Rate*)</p>

              <p>For Sell/Short:</p>
              <p>Bankruptcy Price= Entry Price × (1 + Initial Margin Rate*)</p>
              <p>*Initial Margin Rate (IMR) = 1/ Leverage</p>

              <p>
                For example, traders hold a 1BTC Long position with an entry price at 10,000USDT,
                leverage is 50x.
              </p>
              <p>Bankruptcy Price= 10,000 × (1 - 2%) = 9,800 USDT</p>
            </div>
          </section>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Faq;
