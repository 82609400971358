import React, { ComponentType } from 'react';
import { Route, Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  component: ComponentType<any>;
  path: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('accessToken'); // 토큰 확인 로직

  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
