import React, { useState } from "react";
import AlertPopUp from "./AlertPopUp";

interface FeedbackPopupProps {
  onClose: () => void;
}

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({ onClose }) => {
  const [title, setTitle] = useState("");
  const [memo, setMemo] = useState("");
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");

  const handleSubmit = () => {
    if (title.trim() === "") {
      showAlertPopup("Please input title please");
      return;
    }

    if (memo.trim() === "") {
      showAlertPopup("Please input your feedback please");
      return;
    }

    // Submit feedback
    console.log("Feedback submitted:", { title, memo });

    // Close popup
    onClose();
  };

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-100">
        <h2 className="text-xl font-bold text-[var(--t-color-point)] mb-4">Feedback</h2>
        <input
          type="text"
          placeholder="Input title please"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-4"
        />
        <textarea
          placeholder="Input your feedback please"
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-4"
        />
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-[var(--t-color-point)] border border-[var(--t-color-point)] rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-[var(--t-color-point)] text-white rounded"
          >
            Submit
          </button>
        </div>
      </div>
      {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
    </div>
  );
};

export default FeedbackPopup;
