import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import "../../assets/css/Manager.css";
import HeaderManager from "../../components/ui/HeaderManager";
import axios from "axios";
import { formatDate, formatNumberWithCommas } from "../../components/common/Utils";
import { Link, useNavigate } from "react-router-dom";
import ManagerPartnerList from "../../components/ui/ManagerPartnerList";
import ManagerPartnerInfo from "../../components/ui/ManagerPartnerInfo";
import AlertPopUp from "../../components/ui/AlertPopUp";

const ManagerWithdraw = () => {
  const navigate = useNavigate();

  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [asset, setAsset] = useState<any>(null);
  const [datas, setDatas] = useState<any>([]);
  const [amount, setAmount] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");

  const withdrawManagerFee: number = 50;

  useEffect(() => {
    const level = localStorage.getItem("level");
    let levelNumber: number = 1;
    if (level != null) {
      levelNumber = parseInt(level);
    }
    if (levelNumber < 2) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    requestUserBalance();
    requestWithdrawManagerLookup();
  }, []);

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    // 숫자만 포함하는 정규식 사용
    if (/^\d*$/.test(newValue)) {
      setAmount(newValue);
    }
  };

  const handleWalletAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setWalletAddress(newValue);
  };

  function doAll() {
    if (asset) {
      let possibleAmount: number = Number(asset?.bsc) - withdrawManagerFee;
      if (possibleAmount <= 0) {
        possibleAmount = 0;
      }
      setAmount(possibleAmount.toString());
    }
  }

  function doApply() {
    //console.log(amount);
    if (amount == "" || amount == "0") {
      showAlertPopup("Please enter the withdrawal amount.");
      return;
    }
    if (walletAddress == "") {
      showAlertPopup("Please enter the walletAddress.");
      return;
    }
    registerWithdrawManager();
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  /**
   * api
   */
  const requestUserBalance = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/balance";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code == 0) {
        setAsset(response.data.data);
      }
    } catch (error) {}
  };

  const registerWithdrawManager = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        hostUrl + "/withdraw/registerWithdrawManager",
        {
          amount,
          walletAddress,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log(response);
      if (response.data.code === 0) {
        setAmount("");
        setWalletAddress("");
        requestUserBalance();
        requestWithdrawManagerLookup();
        showAlertPopup("Withdrawal request has been completed.");
      } else {
        showAlertPopup(response.data.message);
      }
    } catch (error) {}
  };

  const requestWithdrawManagerLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/withdraw/withdrawManagerLookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code === 0) {
        setDatas(response.data.data);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <section className="w-full h-full manager">
        <HeaderManager />
        <section className="main">
          <h1 data-lang="title_withdrawal">Withdrawal</h1>
          <section className="withdraw">
            <h4 data-lang="msg_withdraw">
              Batch withdrawals will be processed at 10 PM Korean time. *
            </h4>
            <div className="inner">
              <h3>{asset && formatNumberWithCommas(asset.bsc)}</h3>
              <div className="price">
                <input
                  type="text"
                  value={amount}
                  onChange={handleAmountChange}
                  placeholder="Please enter the withdrawal amount"
                />
                <button onClick={doAll}>All</button>
              </div>
              <div className="address">
                <input
                  type="text"
                  value={walletAddress}
                  onChange={handleWalletAddressChange}
                  placeholder="Please enter the wallet address"
                />
              </div>
              <button data-lang="btn_withdraw_apply" onClick={doApply}>
                Apply
              </button>
            </div>

            <h2 data-lang="subtitle_withdraw_history">Withdrawal History</h2>
            <table>
              <thead>
                <tr>
                  <th data-lang="table_date">Date</th>
                  <th data-lang="table_state">State</th>
                  <th data-lang="table_withdraw_address">Address</th>
                  <th data-lang="table_withdraw_amount">Withdrawal Amounts</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((data: any, index: number) => (
                  <tr key={index} className="text-[0.8rem] text-white">
                    <td>{formatDate(data?.insertDate)}</td>
                    <td>
                      {data.status === 1 && "진행중"}
                      {data.status === 2 && "완료"}
                      {data.status === 3 && "취소"}
                      {data.status === 4 && "거절"}
                    </td>
                    <td>{data.walletAddress}</td>
                    <td>{data.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </section>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
    </div>
  );
};

export default ManagerWithdraw;
