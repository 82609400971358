import React, { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import { formatNumberWithCommas } from "../common/Utils";

interface PercentageSliderProps {
  data: any;
  members: any[];
  title: string;
  bShowDistributions: boolean;
  onFeeChange: (level: number, members: any[]) => void;
}

const ManagerPartnerList: React.FC<PercentageSliderProps> = ({
  title,
  data,
  members,
  bShowDistributions,
  onFeeChange,
}) => {
  const [memberPercentages, setMemberPercentages] = React.useState(
    members.map((member) => ({ ...member, percentage: member.nowFee }))
  );

  const min: number = 0;
  const max: number = 100;

  // Local handler that wraps the external handler and updates local state
  const handleLocalInputChange = (event: ChangeEvent<HTMLInputElement>, no: number) => {
    let { value } = event.target;
    const numValue = parseFloat(value);

    if (numValue < min) {
      value = min.toString();
    } else if (numValue > max) {
      value = max.toString();
    }

    let nowFee = 0;
    if (data?.nowFee != 0 && value != "" && value != "0") {
      nowFee = data?.nowFee * (parseFloat(value) / 100);
    }
    const updatedPercentages = memberPercentages.map((member) =>
      member.no === no ? { ...member, parentsDistributePercent: parseFloat(value), nowFee: nowFee } : member
    );
    
    setMemberPercentages(updatedPercentages);
    onFeeChange(data?.level - 1, updatedPercentages);
  };

  const handleBlur = (no: number) => {
    const updatedPercentages = memberPercentages.map((item: any) => {
      const member = memberPercentages.find(({ no }) => no === item.no);
      if (member) {
        return { ...item, parentsDistributePercent: member.parentsDistributePercent, nowFee: data?.nowFee * (member.parentsDistributePercent / 100)};
      }
      return item;
    });
    onFeeChange(data?.level - 1, updatedPercentages);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, no: number) => {
    if (event.key === "Enter") {
      handleBlur(no);
    }
  };

  return (
    <section>
      <h2 className="mt-[50px] font-bold text-[1.3rem]" data-lang="subtitle_influencer">
        {title}
      </h2>
      <table id={data.level === 3 ? "distribute-table" : undefined}>
        <thead>
          <tr>
            <th data-lang="table_influencer">Influencer(Rating)</th>
            <th data-lang="table_name">Name</th>
            <th data-lang="table_fee_rate">Fee Rates</th>
            {bShowDistributions && <th data-lang="table_fee_dis">Fee Distributions</th>}
            <th data-lang="table_members">Members</th>
            <th data-lang="table_total_fee">Total Fee</th>
            <th data-lang="table_amount">Amount</th>
            <th data-lang="">Settlement</th>
            <th data-lang="table_phone">Phone</th>
            <th data-lang="">Day</th>
            <th data-lang="">Week</th>
            <th data-lang="">Total</th>
          </tr>
        </thead>
        <tbody>
          {memberPercentages.map((member: any, index: number) => (
            <tr key={index} id={member.no}>
              <td>
                {member?.level == 7 && "Secret"}
                {member?.level == 6 && "Super"}
                {member?.level == 5 && "Mega"}
                {member?.level == 4 && "Star"}
                {member?.level == 3 && "Premium"}
                {member?.level == 2 && "Influencer"}
              </td>
              <td>
                <Link to="">{member.name}</Link>
              </td>
              <td>
                <input type="text" id="fee0" value={member.nowFee + "%"} readOnly />
              </td>
              {bShowDistributions && (
                <td>
                  <input
                    type="number"
                    id="feePer0"
                    value={member.parentsDistributePercent}
                    onChange={(event) => handleLocalInputChange(event, member.no)}
                    onBlur={() => handleBlur(member.no)}
                    onKeyPress={(event) => handleKeyPress(event, member.no)}
                    min={0}
                    max={100}
                    step={1}
                  ></input>
                </td>
              )}
              <td>{member.memberCount}</td>
              <td></td>
              <td>
                <select>
                  <option>Futures :</option>
                  <option>BTC :</option>
                  <option>USDT :</option>
                </select>
              </td>
              <td className="settlement">
                {" "}
                {member?.todaySettlement == null
                  ? "0"
                  : formatNumberWithCommas(member?.todaySettlement)}
              </td>
              <td>{member.phone}</td>
              <td className="settlement">
                {member?.todaySettlement == null
                  ? "0"
                  : formatNumberWithCommas(member?.todaySettlement)}
              </td>
              <td className="settlement">
                {member?.weekSettlement == null
                  ? "0"
                  : formatNumberWithCommas(member?.weekSettlement)}
              </td>
              <td className="settlement">
                {member?.totalSettlement == null
                  ? "0"
                  : formatNumberWithCommas(member?.totalSettlement)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default ManagerPartnerList;
